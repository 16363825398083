var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _vm.list.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "div",
                { staticClass: "button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-add-assistant-config",
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCreate()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("assistantConfig.addNewAssistantConfig")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.list,
                    "element-loading-text": "Loading...",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: { align: "center", label: "ID", width: "95" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var $index = ref.$index
                              return [_vm._v(_vm._s($index))]
                            },
                          },
                        ],
                        null,
                        false,
                        3554679633
                      ),
                    },
                    [_vm._v(" an ")]
                  ),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("assistantConfig.region"),
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_vm._v(" " + _vm._s(row.region) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      287340199
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("assistantConfig.workingHours"),
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(row.workingHoursStart) +
                                  " - " +
                                  _vm._s(row.workingHoursEnd) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2212476372
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Working Days",
                      width: "500",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return _vm._l(
                              row.workingDays,
                              function (day, index) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: { type: _vm.getTagType(index) },
                                  },
                                  [_vm._v(_vm._s(day))]
                                )
                              }
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3357029495
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("assistantConfig.state"),
                      align: "center",
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.isActive
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleAssistantConfigActive(
                                            row,
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("assistantConfig.active")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleAssistantConfigActive(
                                            row,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("assistantConfig.passive")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1217301948
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("assistantConfig.actions"),
                      align: "center",
                      "class-name": "fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("assistantConfig.edit")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDeleteConfirmation(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("assistantConfig.delete")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3413817774
                    ),
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.textMap[_vm.dialogStatus],
                    visible: _vm.dialogFormVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogFormVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      staticStyle: { width: "400px", "margin-left": "50px" },
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.tempAssistantConfigData,
                        "label-position": "left",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("assistantConfig.region"),
                            "label-width": "200px",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.tempAssistantConfigData.region,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tempAssistantConfigData,
                                    "region",
                                    $$v
                                  )
                                },
                                expression: "tempAssistantConfigData.region",
                              },
                            },
                            _vm._l(_vm.applicationRegions, function (option) {
                              return _c("el-option", {
                                key: option,
                                attrs: { label: option, value: option },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("assistantConfig.workingDays"),
                            "label-width": "200px",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                placeholder: "Select working days",
                              },
                              model: {
                                value: _vm.tempAssistantConfigData.workingDays,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tempAssistantConfigData,
                                    "workingDays",
                                    $$v
                                  )
                                },
                                expression:
                                  "tempAssistantConfigData.workingDays",
                              },
                            },
                            _vm._l(_vm.days, function (day) {
                              return _c("el-option", {
                                key: day.value,
                                attrs: { label: day.label, value: day.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("assistantConfig.workingHoursStart"),
                            "label-width": "200px",
                            prop: "workingHoursStart",
                          },
                        },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              format: "HH:mm",
                              placeholder: "Select",
                              "value-format": "HH:mm",
                              "picker-options": _vm.pickerOptions,
                            },
                            on: { change: _vm.handleWorkingHoursStartChange },
                            model: {
                              value:
                                _vm.tempAssistantConfigData.workingHoursStart,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tempAssistantConfigData,
                                  "workingHoursStart",
                                  $$v
                                )
                              },
                              expression:
                                "tempAssistantConfigData.workingHoursStart",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("assistantConfig.workingHoursEnd"),
                            "label-width": "200px",
                            prop: "workingHoursEnd",
                          },
                        },
                        [
                          _c("el-time-picker", {
                            attrs: {
                              format: "HH:mm",
                              "value-format": "HH:mm",
                              placeholder: "Select",
                              "picker-options": _vm.pickerOptions,
                            },
                            on: { change: _vm.handleWorkingHoursEndChange },
                            model: {
                              value:
                                _vm.tempAssistantConfigData.workingHoursEnd,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tempAssistantConfigData,
                                  "workingHoursEnd",
                                  $$v
                                )
                              },
                              expression:
                                "tempAssistantConfigData.workingHoursEnd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "isActive",
                            label: _vm.$t("assistantConfig.state"),
                            "label-width": "200px",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "inline-prompt": "",
                              "active-text": _vm.$t("assistantConfig.active"),
                              "inactive-text": _vm.$t(
                                "assistantConfig.passive"
                              ),
                            },
                            model: {
                              value: _vm.tempAssistantConfigData.isActive,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tempAssistantConfigData,
                                  "isActive",
                                  $$v
                                )
                              },
                              expression: "tempAssistantConfigData.isActive",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("table.cancel")) + " ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialogStatus === "create"
                                ? _vm.createData()
                                : _vm.updateData()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("table.confirm")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Delete Item",
                    visible: _vm.deleteConfirmationVisible,
                    width: "30%",
                    "before-close": _vm.handleDeleteConfirmationClose,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.deleteConfirmationVisible = $event
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("assistantConfig.deletePopup"))),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.deleteConfirmationVisible = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("assistantConfig.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.deleteItem },
                        },
                        [_vm._v(_vm._s(_vm.$t("assistantConfig.ok")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _c("div", [_vm._v(_vm._s(_vm.$t("assistantConfig.dataNotAvailable")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
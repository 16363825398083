/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BusLinesResponse } from '../models';
import { ErrorModelResponse } from '../models';
import { GetBusLinesRequest } from '../models';
import { GetClosestStopsRequest } from '../models';
import { GetClosestStopsResponse } from '../models';
import { GetStopsByLineRequest } from '../models';
import { StopsByLineResponse } from '../models';
/**
 * TransportationApi - axios parameter creator
 * @export
 */
export const TransportationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets realtime arrivals by stopId.
         * @param {GetBusLinesRequest} body Gets realtime arrivals by stopId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportationGetArrivalsPost: async (body: GetBusLinesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling transportationGetArrivalsPost.');
            }
            const localVarPath = `/transportation/getArrivals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets closest stops by latitude/longitude query. Radius and max number of results are static.
         * @param {GetClosestStopsRequest} body Gets closest stops by lat/long.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportationGetClosestStopsPost: async (body: GetClosestStopsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling transportationGetClosestStopsPost.');
            }
            const localVarPath = `/transportation/getClosestStops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all stops in sequence by line
         * @param {GetStopsByLineRequest} body Request contains lineGroupId and lineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportationGetStopsByLinePost: async (body: GetStopsByLineRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling transportationGetStopsByLinePost.');
            }
            const localVarPath = `/transportation/getStopsByLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransportationApi - functional programming interface
 * @export
 */
export const TransportationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets realtime arrivals by stopId.
         * @param {GetBusLinesRequest} body Gets realtime arrivals by stopId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transportationGetArrivalsPost(body: GetBusLinesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusLinesResponse>> {
            const localVarAxiosArgs = await TransportationApiAxiosParamCreator(configuration).transportationGetArrivalsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets closest stops by latitude/longitude query. Radius and max number of results are static.
         * @param {GetClosestStopsRequest} body Gets closest stops by lat/long.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transportationGetClosestStopsPost(body: GetClosestStopsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClosestStopsResponse>> {
            const localVarAxiosArgs = await TransportationApiAxiosParamCreator(configuration).transportationGetClosestStopsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all stops in sequence by line
         * @param {GetStopsByLineRequest} body Request contains lineGroupId and lineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transportationGetStopsByLinePost(body: GetStopsByLineRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopsByLineResponse>> {
            const localVarAxiosArgs = await TransportationApiAxiosParamCreator(configuration).transportationGetStopsByLinePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransportationApi - factory interface
 * @export
 */
export const TransportationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets realtime arrivals by stopId.
         * @param {GetBusLinesRequest} body Gets realtime arrivals by stopId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportationGetArrivalsPost(body: GetBusLinesRequest, options?: any): AxiosPromise<BusLinesResponse> {
            return TransportationApiFp(configuration).transportationGetArrivalsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets closest stops by latitude/longitude query. Radius and max number of results are static.
         * @param {GetClosestStopsRequest} body Gets closest stops by lat/long.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportationGetClosestStopsPost(body: GetClosestStopsRequest, options?: any): AxiosPromise<GetClosestStopsResponse> {
            return TransportationApiFp(configuration).transportationGetClosestStopsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all stops in sequence by line
         * @param {GetStopsByLineRequest} body Request contains lineGroupId and lineId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transportationGetStopsByLinePost(body: GetStopsByLineRequest, options?: any): AxiosPromise<StopsByLineResponse> {
            return TransportationApiFp(configuration).transportationGetStopsByLinePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransportationApi - object-oriented interface
 * @export
 * @class TransportationApi
 * @extends {BaseAPI}
 */
export class TransportationApi extends BaseAPI {
    /**
     * 
     * @summary Gets realtime arrivals by stopId.
     * @param {GetBusLinesRequest} body Gets realtime arrivals by stopId.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportationApi
     */
    public transportationGetArrivalsPost(body: GetBusLinesRequest, options?: any) {
        return TransportationApiFp(this.configuration).transportationGetArrivalsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets closest stops by latitude/longitude query. Radius and max number of results are static.
     * @param {GetClosestStopsRequest} body Gets closest stops by lat/long.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportationApi
     */
    public transportationGetClosestStopsPost(body: GetClosestStopsRequest, options?: any) {
        return TransportationApiFp(this.configuration).transportationGetClosestStopsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets all stops in sequence by line
     * @param {GetStopsByLineRequest} body Request contains lineGroupId and lineId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransportationApi
     */
    public transportationGetStopsByLinePost(body: GetStopsByLineRequest, options?: any) {
        return TransportationApiFp(this.configuration).transportationGetStopsByLinePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}

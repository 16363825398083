











































































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminApi } from '@/wwapi/apis/admin-api'
import { LocalizationTextsForAdminResponse } from '@/wwapi/models/localization-texts-for-admin-response'
import { LocalizationForAdminResponseModel } from '@/wwapi/models/localization-for-admin-response-model'
import { Form } from 'element-ui'
import { defaultLocalizationData } from '../../defaultModels/ILocalizationData'

@Component({
  name: 'ExportZip'
})
export default class extends Vue {
  private list: any[] = [];
  private listLoading = true;
  private downloadLoading = false;
  private filename = '';
  private tempLocData = defaultLocalizationData;
  private dialogFormVisible = false;
  private dialogStatus = '';
  private listQuery = {
    text: null
  };

  private textMap = {
    update: 'Edit',
    create: 'Create'
  };

  created() {
    const key = this.$route.query ? (this.$route.query.key as string) : ''
    const andKey = this.$route.query
      ? (this.$route.query.andKey as string)
      : ''

    this.fetchData(key, andKey)
  }

  private async fetchData(key: string, andKey: string) {
    this.listLoading = true
    const adminApi = new AdminApi()

    const response = await adminApi.localizationGetDetailByKeyPost(
      { key: key, andKey: andKey },
      0
    )
    const responseModel: LocalizationTextsForAdminResponse = response.data
    const localizationModel: LocalizationForAdminResponseModel =
      responseModel.data

    this.list = localizationModel
    // console.log("firstkey: " + localizationModel[0].key);
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  private async filterData() {
    this.listLoading = true
    const adminApi = new AdminApi()

    const response = await adminApi.localizationFilterPost(this.listQuery, 0)
    const responseModel: LocalizationTextsForAdminResponse = response.data
    const texts: LocalizationForAdminResponseModel = responseModel.data
    if (texts.length && texts.length > 0) this.list = texts
    else this.list = []

    // this.list = response.data.items;
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  private handleFilter() {
    this.filterData()
  }

  private handleUpdate(row: any) {
    this.tempLocData = Object.assign({}, row)
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private updateData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempLocData)
        const { data } = await adminApi.localizationUpdateLocalizationKeyPost({
          key: tempData.key,
          andKey: tempData.andKey,
          value: tempData.value,
          languageKey: tempData.languageKey
        })
        const index = this.list.findIndex(
          (v: any) =>
            v.key === data.data.key && v.languageKey === data.data.languageKey
        )
        this.list.splice(index, 1, data.data)
        this.dialogFormVisible = false
        this.$notify({
          title: 'Success',
          message: 'Successfully updated the key',
          type: 'success',
          duration: 2000
        })
      }
    })
  }
}

export const defaultUserData: any = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumberr: '',
  countryCode: '',
  country: '',
  city: '',
  birthDay: new Date(),
  userType: '',
  caneId: '',
  isAssistant: false,
  assistantRegion: '',
  consultSeconds: 0,
}

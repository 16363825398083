










































































































































































































































import L from "leaflet";
import { Component, Vue, Watch } from "vue-property-decorator";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { cloneDeep } from "lodash";
import { Form } from "element-ui";
import { defaultFreeZoneData } from "../../defaultModels/IFreeZoneData";
import NumericInput from "@/components/NumericInput/index.vue";
import { GetFreeZonesResponse } from "@/wwapi/models/get-freezones-response";

@Component({
  name: "FreeZoneList",
  components: {
    NumericInput,
  },
})
export default class extends Vue {
  private list: GetFreeZonesResponse[] = [];
  private options: Array<any> = [];
  private listLoading = true;
  private dialogFormVisible = false;
  private deleteConfirmationVisible = false;
  private deleteItemId = null;
  private dialogStatus = "";
  private errorMessage = "";
  private tempFreeZoneData = defaultFreeZoneData;
  private lat = 0;
  private long = 0;
  private range = 0;
  private map!: L.Map;
  private centerMarker!: L.Marker;
  private circle!: L.Circle;
  private textMap = {
    update: "Edit",
    create: "Create",
  };

  created() {
    this.fetchData();
  }

  mounted() {
    this.getCompanies();
    this.updateRadius();
  }

  updated() {
    if (!this.map) {
      this.map = L.map("map").setView([41.015137, 28.97953], 5);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "",
        maxZoom: 18,
      }).addTo(this.map);

      this.centerMarker = L.marker([41.015137, 28.97953], {
        draggable: true,
        opacity: 0.8,
        zIndexOffset: 1000,
      }).addTo(this.map);

      this.circle = L.circle([0, 0], { radius: 0 }).addTo(this.map);
    }
  }

  @Watch("tempFreeZoneData.latitude")
  @Watch("tempFreeZoneData.longitude")
  @Watch("tempFreeZoneData.range")
  private updateRadius() {
    const lat = this.tempFreeZoneData.latitude;
    const long = this.tempFreeZoneData.longitude;
    const range = this.tempFreeZoneData.range;

    // Lat/Long değerleriyle işaretçiyi güncelleyin
    if (lat && long) {
      if (this.centerMarker) {
        this.centerMarker.setLatLng([lat, long]);
      }
      if (this.map) {
        this.map.setView([lat, long], this.map.getZoom());
      }
    }

    // Yarıçapı güncelleyin
    if (range && this.circle) {
      this.circle.setRadius(range);
      this.circle.setLatLng([lat, long]);
    }
  }

  handleError(error: string) {
    this.errorMessage = error;
  }

  private async getCompanies() {
    const adminApi = new AdminApi();
    const response = await adminApi.getCompanies({
      includeIsActiveCondition: true,
    });
    this.options = response.data.data.map((option) => {
      return {
        label: option.name,
        value: option.id,
      };
    });
  }

  private async fetchData() {
    this.listLoading = true;
    const adminApi = new AdminApi();
    const response = await adminApi.getFreeZones();
    if (response.data.data.length) this.list = response.data.data;
    else this.list = [];
    console.log(
      "data returned from getCompanies" + JSON.stringify(response.data.data)
    );
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  showDeleteConfirmation(row: any) {
    this.deleteItemId = row.id;
    this.deleteConfirmationVisible = true;
  }

  handleDeleteConfirmationClose(done: any) {
    if (done) {
      this.deleteItemId = null;
      this.deleteConfirmationVisible = false;
    }
  }

  async deleteItem() {
    console.log(`Deleting item with ID: ${this.deleteItemId}`);
    const adminApi = new AdminApi();
    const { data } = await adminApi.deleteFreeZone({
      freezoneId: this.deleteItemId,
    });
    if (data.status.code === "success") {
      const index = this.list.findIndex((v: any) => v.id === this.deleteItemId);
      this.list.splice(index, 1);
      this.$notify({
        title: "Success",
        message: "Successfully freezone deleted",
        type: "success",
        duration: 2000,
      });
    }
    this.deleteItemId = null;
    this.deleteConfirmationVisible = false;
  }

  private handleUpdate(row: any) {
    this.tempFreeZoneData = Object.assign({}, row);
    this.dialogStatus = "update";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private updateData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempFreeZoneData);
        const { data } = await adminApi.updateFreeZone({
          id: tempData.id,
          name: tempData.name,
          sponsorshipId: tempData.sponsorshipId,
          latitude: tempData.latitude,
          longitude: tempData.longitude,
          isCity: tempData.isCity,
          isActive: tempData.isActive,
          range: tempData.range,
          isRemoved: tempData.isRemoved,
        });
        const index = this.list.findIndex((v: any) => v.id === tempData.id);
        if (data.status.code === "success") {
          this.list.splice(index, 1, tempData);
          this.dialogFormVisible = false;
          this.$notify({
            title: "Success",
            message: "Successfully update the freezone",
            type: "success",
            duration: 2000,
          });
        }
      }
    });
  }

  private handleCreate() {
    this.tempFreeZoneData = cloneDeep(defaultFreeZoneData);
    this.dialogStatus = "create";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private createData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempFreeZoneData);
        const { data } = await adminApi.postFreeZone({
          name: tempData.name,
          sponsorshipId: tempData.sponsorshipId,
          latitude: tempData.latitude,
          longitude: tempData.longitude,
          isCity: tempData.isCity,
          isActive: tempData.isActive,
          range: tempData.range,
          isRemoved: tempData.isRemoved,
        });
        if (data && data.data) {
          this.list = [...this.list, data.data];
        }
        this.dialogFormVisible = false;
        this.$notify({
          title: "Success",
          message: "Successfully added new freeZone",
          type: "success",
          duration: 2000,
        });
      }
    });
  }

  private async toggleFreeZoneActive(row: any, isActive: boolean) {
    const adminApi = new AdminApi();
    row.isActive = isActive;
    const { data } = await adminApi.updateFreeZone({
      id: row.id,
      name: row.name,
      sponsorshipId: row.sponsorshipId,
      latitude: row.latitude,
      longitude: row.longitude,
      isCity: row.isCity,
      isActive: row.isActive,
      range: row.range,
      isRemoved: row.isRemoved,
    });
    if (data.status.code === "success") {
      if (!isActive) {
        const index = this.list.findIndex((v: any) => v.id === data.data.id);
        this.list.splice(index, 1, row);
      }
      this.$notify({
        title: "Success",
        message: "Successfully freezone status changed",
        type: "success",
        duration: 2000,
      });
    }
  }
}
























































import { Component, Vue } from 'vue-property-decorator'
import { handleClipboard } from '@/utils/clipboard'
import elementIcons from './element-icons'
import svgIcons from './svg-icons'

@Component({
  name: 'Icons'
})
export default class extends Vue {
  private svgIcons = svgIcons;
  private elementIcons = elementIcons;
  private handleClipboard = handleClipboard;

  private generateElementIconCode(symbol: string) {
    return `<i class="el-icon-${symbol}" />`
  }

  private generateSvgIconCode(symbol: string) {
    return `<svg-icon name="${symbol}" />`
  }
}

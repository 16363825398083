export const defaultBrandSubjectData: any = {
  id: "",
  consultSubjectId: "",
  consultBrandId: "",
  orderNo: 0,
  isConsultFree: false,
  isActive: false,
  consultSubjectName: "",
  consultBrandName: "",
  isRemoved: false,
};

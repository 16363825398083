export const defaultFreeZoneData: any = {
  id: '',
  sponsorshipId: '',
  latitude: 0,
  longitude: 0,
  isCity: false,
  range: 0,
  isActive: false,
  name: '',
  isRemoved: false
}

/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EmptyRequest } from '../models';
import { ErrorModelResponse } from '../models';
import { VisualImpairementsResponse } from '../models';
import { VoicePackagesResponse } from '../models';
/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets tutorial voice packages from Azure Storage.
         * @param {EmptyRequest} body Empty request
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetTutorialVoicesPost: async (body: EmptyRequest, contentLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contentGetTutorialVoicesPost.');
            }
            const localVarPath = `/content/getTutorialVoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets visual impairement categories.
         * @param {EmptyRequest} body Empty request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetVisualImpairementsPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contentGetVisualImpairementsPost.');
            }
            const localVarPath = `/content/getVisualImpairements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets tutorial voice packages from Azure Storage.
         * @param {EmptyRequest} body Empty request
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentGetTutorialVoicesPost(body: EmptyRequest, contentLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoicePackagesResponse>> {
            const localVarAxiosArgs = await ContentApiAxiosParamCreator(configuration).contentGetTutorialVoicesPost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets visual impairement categories.
         * @param {EmptyRequest} body Empty request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentGetVisualImpairementsPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualImpairementsResponse>> {
            const localVarAxiosArgs = await ContentApiAxiosParamCreator(configuration).contentGetVisualImpairementsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets tutorial voice packages from Azure Storage.
         * @param {EmptyRequest} body Empty request
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetTutorialVoicesPost(body: EmptyRequest, contentLanguage?: string, options?: any): AxiosPromise<VoicePackagesResponse> {
            return ContentApiFp(configuration).contentGetTutorialVoicesPost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets visual impairement categories.
         * @param {EmptyRequest} body Empty request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentGetVisualImpairementsPost(body: EmptyRequest, options?: any): AxiosPromise<VisualImpairementsResponse> {
            return ContentApiFp(configuration).contentGetVisualImpairementsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * 
     * @summary Gets tutorial voice packages from Azure Storage.
     * @param {EmptyRequest} body Empty request
     * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentGetTutorialVoicesPost(body: EmptyRequest, contentLanguage?: string, options?: any) {
        return ContentApiFp(this.configuration).contentGetTutorialVoicesPost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets visual impairement categories.
     * @param {EmptyRequest} body Empty request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentGetVisualImpairementsPost(body: EmptyRequest, options?: any) {
        return ContentApiFp(this.configuration).contentGetVisualImpairementsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserModule } from './../store/modules/user'
import { Configuration } from './configuration'

// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'

const _BASE_PATH = process.env.VUE_APP_API_URL
console.log('env is ' + _BASE_PATH)
export let BASE_PATH = ''
if (_BASE_PATH) {
  BASE_PATH = _BASE_PATH.replace(/\/+$/, '')
}

const _BASE_PATH_V3 = process.env.VUE_APP_API_URL_V3
console.log('env is ' + _BASE_PATH_V3)
export let BASE_PATH_V3 = ''
if (_BASE_PATH_V3) {
  BASE_PATH_V3 = _BASE_PATH_V3.replace(/\/+$/, '')
}
// export const BASE_PATH = "https://734c-31-145-75-82.ngrok.io/api/v2".replace(/\/+$/, "");
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
}

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string
  options: any
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios,
  ) {
    let token = UserModule.token
    if (token) {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: 'RequiredError' = 'RequiredError'
  constructor(public field: string, msg?: string) {
    super(msg)
  }
}

<template>
  <div>
    <el-input
      v-model="inputValue"
      :placeholder="placeholder"
      @input="validateInput"
      :error="inputError"
    ></el-input>
    <div class="error-message">{{ inputError }}</div>
  </div>
</template>

<script>
export default {
  name: 'NumericInput',
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    placeholder: {
      type: String,
      default: 'Enter a number'
    }
  },
  data() {
    return {
      inputValue: this.value,
      inputError: ''
    }
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue
    }
  },
  methods: {
    validateInput() {
      const value = this.inputValue
      if (isNaN(value) || value === null || value === '') {
        this.inputError = 'Please enter a valid number'
        this.$emit('error', this.inputError)
      } else if (value.includes('.')) {
        if (value.split('.')[1].length > 6) {
          this.inputError = 'Please enter a valid float'
          this.$emit('error', this.inputError)
        } else {
          this.inputError = ''
          this.$emit('error', '')
        }
      } else {
        this.inputError = ''
        this.$emit('error', '')
      }
      this.$emit('input', this.inputValue)
    }
  }
}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>

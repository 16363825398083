/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorModelResponse } from '../models';
import { GenericBooleanResponse } from '../models';
import { GetLocalizationTextsRequest } from '../models';
import { GetTTSRequest } from '../models';
import { GetTTSVoiceFilesRequest } from '../models';
import { LanguagesResponse } from '../models';
import { LocalizationTextsResponse } from '../models';
import { TTSResponse } from '../models';
import { TTSVoiceFilesResponse } from '../models';
/**
 * LocalizationApi - axios parameter creator
 * @export
 */
export const LocalizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary import localization excel
         * @param {Blob} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localizationImportLocalizationFilePostForm: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file', 'Required parameter file was null or undefined when calling localizationImportLocalizationFilePostForm.');
            }
            const localVarPath = `/localization/importLocalizationFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gathers all the available languages in WeWalk apps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localizationLanguagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/localization/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gathers all the key/value pairs in a languageKey by languageVersion.
         * @param {GetLocalizationTextsRequest} body Request containing latest localization version number
         * @param {number} [client] ui:0 - ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localizationTextsPost: async (body: GetLocalizationTextsRequest, client?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling localizationTextsPost.');
            }
            const localVarPath = `/localization/texts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (client !== undefined && client !== null) {
                localVarHeaderParameter['client'] = String(JSON.stringify(client));
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Data to be TTSed must be sent in request body and language must be sent in header parameter 'content-language'
         * @param {GetTTSRequest} body Request containing text to be TTSed
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localizationTtsPost: async (body: GetTTSRequest, contentLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling localizationTtsPost.');
            }
            const localVarPath = `/localization/tts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets tts voice file urls from Azure Storage.
         * @param {GetTTSVoiceFilesRequest} body Request containing latest tts voice files version number
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localizationVoiceFilesPost: async (body: GetTTSVoiceFilesRequest, contentLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling localizationVoiceFilesPost.');
            }
            const localVarPath = `/localization/voiceFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions: AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalizationApi - functional programming interface
 * @export
 */
export const LocalizationApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary import localization excel
         * @param {Blob} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationImportLocalizationFilePostForm(file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GenericBooleanResponse>>> {
            const localVarAxiosArgs = await LocalizationApiAxiosParamCreator(configuration).localizationImportLocalizationFilePostForm(file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gathers all the available languages in WeWalk apps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationLanguagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<LanguagesResponse>>> {
            const localVarAxiosArgs = await LocalizationApiAxiosParamCreator(configuration).localizationLanguagesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gathers all the key/value pairs in a languageKey by languageVersion.
         * @param {GetLocalizationTextsRequest} body Request containing latest localization version number
         * @param {number} [client] ui:0 - ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationTextsPost(body: GetLocalizationTextsRequest, client?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<LocalizationTextsResponse>>> {
            const localVarAxiosArgs = await LocalizationApiAxiosParamCreator(configuration).localizationTextsPost(body, client, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Data to be TTSed must be sent in request body and language must be sent in header parameter 'content-language'
         * @param {GetTTSRequest} body Request containing text to be TTSed
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationTtsPost(body: GetTTSRequest, contentLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TTSResponse>>> {
            const localVarAxiosArgs = await LocalizationApiAxiosParamCreator(configuration).localizationTtsPost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets tts voice file urls from Azure Storage.
         * @param {GetTTSVoiceFilesRequest} body Request containing latest tts voice files version number
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationVoiceFilesPost(body: GetTTSVoiceFilesRequest, contentLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TTSVoiceFilesResponse>>> {
            const localVarAxiosArgs = await LocalizationApiAxiosParamCreator(configuration).localizationVoiceFilesPost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs: AxiosRequestConfig = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocalizationApi - factory interface
 * @export
 */
export const LocalizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary import localization excel
         * @param {Blob} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationImportLocalizationFilePostForm(file: any, options?: AxiosRequestConfig): Promise<AxiosResponse<GenericBooleanResponse>> {
            return LocalizationApiFp(configuration).localizationImportLocalizationFilePostForm(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gathers all the available languages in WeWalk apps.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationLanguagesGet(options?: AxiosRequestConfig): Promise<AxiosResponse<LanguagesResponse>> {
            return LocalizationApiFp(configuration).localizationLanguagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gathers all the key/value pairs in a languageKey by languageVersion.
         * @param {GetLocalizationTextsRequest} body Request containing latest localization version number
         * @param {number} [client] ui:0 - ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationTextsPost(body: GetLocalizationTextsRequest, client?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<LocalizationTextsResponse>> {
            return LocalizationApiFp(configuration).localizationTextsPost(body, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Data to be TTSed must be sent in request body and language must be sent in header parameter 'content-language'
         * @param {GetTTSRequest} body Request containing text to be TTSed
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationTtsPost(body: GetTTSRequest, contentLanguage?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TTSResponse>> {
            return LocalizationApiFp(configuration).localizationTtsPost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets tts voice file urls from Azure Storage.
         * @param {GetTTSVoiceFilesRequest} body Request containing latest tts voice files version number
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localizationVoiceFilesPost(body: GetTTSVoiceFilesRequest, contentLanguage?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TTSVoiceFilesResponse>> {
            return LocalizationApiFp(configuration).localizationVoiceFilesPost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalizationApi - object-oriented interface
 * @export
 * @class LocalizationApi
 * @extends {BaseAPI}
 */
export class LocalizationApi extends BaseAPI {
    /**
     * 
     * @summary import localization excel
     * @param {Blob} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationApi
     */
    public async localizationImportLocalizationFilePostForm(file: any, options?: AxiosRequestConfig): Promise<AxiosResponse<GenericBooleanResponse>> {
        return LocalizationApiFp(this.configuration).localizationImportLocalizationFilePostForm(file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gathers all the available languages in WeWalk apps.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationApi
     */
    public async localizationLanguagesGet(options?: AxiosRequestConfig): Promise<AxiosResponse<LanguagesResponse>> {
        return LocalizationApiFp(this.configuration).localizationLanguagesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gathers all the key/value pairs in a languageKey by languageVersion.
     * @param {GetLocalizationTextsRequest} body Request containing latest localization version number
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationApi
     */
    public async localizationTextsPost(body: GetLocalizationTextsRequest, client?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<LocalizationTextsResponse>> {
        return LocalizationApiFp(this.configuration).localizationTextsPost(body, client, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Data to be TTSed must be sent in request body and language must be sent in header parameter 'content-language'
     * @param {GetTTSRequest} body Request containing text to be TTSed
     * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationApi
     */
    public async localizationTtsPost(body: GetTTSRequest, contentLanguage?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TTSResponse>> {
        return LocalizationApiFp(this.configuration).localizationTtsPost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets tts voice file urls from Azure Storage.
     * @param {GetTTSVoiceFilesRequest} body Request containing latest tts voice files version number
     * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationApi
     */
    public async localizationVoiceFilesPost(body: GetTTSVoiceFilesRequest, contentLanguage?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TTSVoiceFilesResponse>> {
        return LocalizationApiFp(this.configuration).localizationVoiceFilesPost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plus': {
    width: 128,
    height: 128,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 12h12m-6-6v12" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})

import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { getToken, setToken, removeToken, getRefreshToken, setRefreshToken, removeRefreshToken } from '@/utils/cookies';
import store from '@/store';
import { UserApi } from '@/wwapi';
import { UserResponse, UserModel, EmptyRequest } from '@/wwapi/models';
import router, { resetRouter } from '@/router'
import { PermissionModule } from './permission';
import { TagsViewModule } from './tags-view';

export interface IUserState {
  token: string;
  refreshToken: string;
  name: string;
  avatar: string;
  introduction: string;
  roles: string[];
  email: string;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || '';
  public refreshToken = getRefreshToken() || '';
  public name = '';
  public avatar = '';
  public introduction = '';
  public roles: string[] = [];
  public email = '';

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_REFRESH_TOKEN(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar;
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction;
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email;
  }

  @Action
  public async Login({ token, refreshToken }: { token: string; refreshToken: string }) {
    setToken(token);
    setRefreshToken(refreshToken);
    this.context.commit('SET_TOKEN', token);
    this.context.commit('SET_REFRESH_TOKEN', refreshToken);
  }

  @Action
  public ResetToken() {
    removeToken();
    removeRefreshToken();
    this.context.commit('SET_TOKEN', '');
    this.context.commit('SET_REFRESH_TOKEN', '');
    this.context.commit('SET_ROLES', []);
  }

  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!');
    }

    const uapi = new UserApi();
    const options = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    };
    console.log('header2 is: ' + JSON.stringify(options));
    const response = await uapi.authGetUserInfoGet(options);
    const responseModel: UserResponse = response.data;
    const userModel: UserModel = responseModel.data;

    if (!userModel) {
      throw Error('Verification failed, please Login again.');
    }
    if (!userModel.userType || userModel.userType.length <= 0) {
      alert('Roles must not be empty!\nPlease contact ibrahim :P');
      throw Error('GetUserInfo: roles must be a non-null array!');
    }

    this.context.commit('SET_ROLES', [userModel.userType]);
    this.context.commit('SET_NAME', userModel.firstName + ' ' + userModel.lastName);
    this.context.commit('SET_AVATAR', 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif');
    this.context.commit('SET_INTRODUCTION', userModel.userType);
    this.context.commit('SET_EMAIL', userModel.email);
  }

  @Action
  public async ChangeRoles(role: string) {
    const token = role + '-token';
    this.context.commit('SET_TOKEN', token);
    setToken(token);
    await this.GetUserInfo();
    resetRouter();
    PermissionModule.GenerateRoutes(this.roles);
    PermissionModule.dynamicRoutes.forEach(route => {
      router.addRoute(route);
    });
    TagsViewModule.delAllViews();
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!');
    }
    const uapi = new UserApi();
    const logoutRequest: EmptyRequest = {};
    await uapi.authSignoutPost(logoutRequest);
    removeToken();
    removeRefreshToken();
    resetRouter();
    TagsViewModule.delAllViews();
    this.context.commit('SET_TOKEN', '');
    this.context.commit('SET_ROLES', []);
  }
}

export const UserModule = getModule(User);

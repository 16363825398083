/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorModelResponse } from '../models';
import { GetPOIsRequest } from '../models';
import { GetVenueDetailsRequest } from '../models';
import { POIsResponse } from '../models';
import { SearchVenuesRequest } from '../models';
import { SearchVenuesResponse } from '../models';
import { VenueDetailsResponse } from '../models';
/**
 * ExploreApi - axios parameter creator
 * @export
 */
export const ExploreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets explore venues for a coordinate with categoryId in a predefined radius. Language must be sent in header parameter 'content-language'
         * @param {GetPOIsRequest} body Request containing coordinates and categoryId
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreExploreVenuesPost: async (body: GetPOIsRequest, contentLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling exploreExploreVenuesPost.');
            }
            const localVarPath = `/explore/exploreVenues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets point of interests for a coordinates with categoryId. Language must be sent in header parameter 'content-language'
         * @param {GetPOIsRequest} body Request containing coordinates and categoryId
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreGetPointOfInterestsPost: async (body: GetPOIsRequest, contentLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling exploreGetPointOfInterestsPost.');
            }
            const localVarPath = `/explore/getPointOfInterests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the phone number of the venue with id. Language must be sent in header parameter 'content-language'
         * @param {GetVenueDetailsRequest} body Request containing venue id
         * @param {string} contentLanguage Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreGetVenueDetailsPost: async (body: GetVenueDetailsRequest, contentLanguage: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling exploreGetVenueDetailsPost.');
            }
            // verify required parameter 'contentLanguage' is not null or undefined
            if (contentLanguage === null || contentLanguage === undefined) {
                throw new RequiredError('contentLanguage','Required parameter contentLanguage was null or undefined when calling exploreGetVenueDetailsPost.');
            }
            const localVarPath = `/explore/getVenueDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets venues for a coordinates with query. Language must be sent in header parameter 'content-language'
         * @param {SearchVenuesRequest} body Request containing coordinates and query for venues
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreSearchVenuesPost: async (body: SearchVenuesRequest, contentLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling exploreSearchVenuesPost.');
            }
            const localVarPath = `/explore/searchVenues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExploreApi - functional programming interface
 * @export
 */
export const ExploreApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets explore venues for a coordinate with categoryId in a predefined radius. Language must be sent in header parameter 'content-language'
         * @param {GetPOIsRequest} body Request containing coordinates and categoryId
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exploreExploreVenuesPost(body: GetPOIsRequest, contentLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<POIsResponse>> {
            const localVarAxiosArgs = await ExploreApiAxiosParamCreator(configuration).exploreExploreVenuesPost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets point of interests for a coordinates with categoryId. Language must be sent in header parameter 'content-language'
         * @param {GetPOIsRequest} body Request containing coordinates and categoryId
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exploreGetPointOfInterestsPost(body: GetPOIsRequest, contentLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<POIsResponse>> {
            const localVarAxiosArgs = await ExploreApiAxiosParamCreator(configuration).exploreGetPointOfInterestsPost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the phone number of the venue with id. Language must be sent in header parameter 'content-language'
         * @param {GetVenueDetailsRequest} body Request containing venue id
         * @param {string} contentLanguage Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exploreGetVenueDetailsPost(body: GetVenueDetailsRequest, contentLanguage: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VenueDetailsResponse>> {
            const localVarAxiosArgs = await ExploreApiAxiosParamCreator(configuration).exploreGetVenueDetailsPost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets venues for a coordinates with query. Language must be sent in header parameter 'content-language'
         * @param {SearchVenuesRequest} body Request containing coordinates and query for venues
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exploreSearchVenuesPost(body: SearchVenuesRequest, contentLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchVenuesResponse>> {
            const localVarAxiosArgs = await ExploreApiAxiosParamCreator(configuration).exploreSearchVenuesPost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExploreApi - factory interface
 * @export
 */
export const ExploreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets explore venues for a coordinate with categoryId in a predefined radius. Language must be sent in header parameter 'content-language'
         * @param {GetPOIsRequest} body Request containing coordinates and categoryId
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreExploreVenuesPost(body: GetPOIsRequest, contentLanguage?: string, options?: any): AxiosPromise<POIsResponse> {
            return ExploreApiFp(configuration).exploreExploreVenuesPost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets point of interests for a coordinates with categoryId. Language must be sent in header parameter 'content-language'
         * @param {GetPOIsRequest} body Request containing coordinates and categoryId
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreGetPointOfInterestsPost(body: GetPOIsRequest, contentLanguage?: string, options?: any): AxiosPromise<POIsResponse> {
            return ExploreApiFp(configuration).exploreGetPointOfInterestsPost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the phone number of the venue with id. Language must be sent in header parameter 'content-language'
         * @param {GetVenueDetailsRequest} body Request containing venue id
         * @param {string} contentLanguage Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreGetVenueDetailsPost(body: GetVenueDetailsRequest, contentLanguage: string, options?: any): AxiosPromise<VenueDetailsResponse> {
            return ExploreApiFp(configuration).exploreGetVenueDetailsPost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets venues for a coordinates with query. Language must be sent in header parameter 'content-language'
         * @param {SearchVenuesRequest} body Request containing coordinates and query for venues
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exploreSearchVenuesPost(body: SearchVenuesRequest, contentLanguage?: string, options?: any): AxiosPromise<SearchVenuesResponse> {
            return ExploreApiFp(configuration).exploreSearchVenuesPost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExploreApi - object-oriented interface
 * @export
 * @class ExploreApi
 * @extends {BaseAPI}
 */
export class ExploreApi extends BaseAPI {
    /**
     * 
     * @summary Gets explore venues for a coordinate with categoryId in a predefined radius. Language must be sent in header parameter 'content-language'
     * @param {GetPOIsRequest} body Request containing coordinates and categoryId
     * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public exploreExploreVenuesPost(body: GetPOIsRequest, contentLanguage?: string, options?: any) {
        return ExploreApiFp(this.configuration).exploreExploreVenuesPost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets point of interests for a coordinates with categoryId. Language must be sent in header parameter 'content-language'
     * @param {GetPOIsRequest} body Request containing coordinates and categoryId
     * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public exploreGetPointOfInterestsPost(body: GetPOIsRequest, contentLanguage?: string, options?: any) {
        return ExploreApiFp(this.configuration).exploreGetPointOfInterestsPost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the phone number of the venue with id. Language must be sent in header parameter 'content-language'
     * @param {GetVenueDetailsRequest} body Request containing venue id
     * @param {string} contentLanguage Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public exploreGetVenueDetailsPost(body: GetVenueDetailsRequest, contentLanguage: string, options?: any) {
        return ExploreApiFp(this.configuration).exploreGetVenueDetailsPost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets venues for a coordinates with query. Language must be sent in header parameter 'content-language'
     * @param {SearchVenuesRequest} body Request containing coordinates and query for venues
     * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExploreApi
     */
    public exploreSearchVenuesPost(body: SearchVenuesRequest, contentLanguage?: string, options?: any) {
        return ExploreApiFp(this.configuration).exploreSearchVenuesPost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: {
              type: "date",
              placeholder: "Start Date",
              "value-format": "yyyy-MM-dd",
            },
            model: {
              value: _vm.listQuery.startDate,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "startDate", $$v)
              },
              expression: "listQuery.startDate",
            },
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: {
              type: "date",
              placeholder: "End Date",
              "value-format": "yyyy-MM-dd",
            },
            model: {
              value: _vm.listQuery.endDate,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "endDate", $$v)
              },
              expression: "listQuery.endDate",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px", "margin-right": "20px" },
              attrs: { placeholder: _vm.$t("table.country"), clearable: "" },
              model: {
                value: _vm.listQuery.country,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "country", $$v)
                },
                expression: "listQuery.country",
              },
            },
            _vm._l(_vm.countryOptions, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.displayName, value: item.key },
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: { placeholder: _vm.$t("table.userIdSearch") },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.listQuery.userId,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "userId", $$v)
              },
              expression: "listQuery.userId",
            },
          }),
          _c("br"),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("table.search") },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.listQuery.text,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "text", $$v)
              },
              expression: "listQuery.text",
            },
          }),
          _c("br"),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          ),
          _c(
            "a",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { href: _vm.generateDynamicURL() },
            },
            [
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-download" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("table.export")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { "margin-bottom": "20px" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { align: "center", label: "ID", width: "95" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var $index = ref.$index
                    return [_vm._v(_vm._s($index))]
                  },
                },
              ]),
            },
            [_vm._v(" an ")]
          ),
          _c("el-table-column", {
            attrs: { label: "Room Sid" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.roomSid) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "First Name", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.firstName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Last Name", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.lastName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Email", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.email))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "User Id", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.userId))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Country", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.country))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Assistant Name", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.assistantUserName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Assistant Duration", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.assistantDuration))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "User Duration", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.userDuration))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "User Is FreeZone", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.userIsFreezone === 1 ? "True" : "False")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Assistant Call Start", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.assistantCreatedAt))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "User Call Start", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.userCreatedAt))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Assistant Call End", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.assistantUpdatedAt))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "User Call End", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.userUpdatedAt))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Survey Description", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.surveyDescription))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Survey IsSatisfied", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.surveyIsSatisfied == 1 ? "True" : "False")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Survey IsAnswered", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.surveyIsAnswered == 1 ? "True" : "False")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Survey CreatedAT", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.surveyCreatedAt))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "User Total Duration", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.totalDuration))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.listQuery.page,
          "page-size": _vm.listQuery.pageSize,
          "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000],
          small: _vm.small,
          background: true,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.totalCount,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("br"),
    _vm.isLoggedIn
      ? _c(
          "a",
          {
            staticClass: "redirect-button btn btn-primary",
            attrs: { href: _vm.weAssistLink, target: "_blank" },
          },
          [_c("el-button", [_vm._v(" Login to WeAssist Panel ")])],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorModelResponse } from '../models';
import { SubscriptionProductsRequest } from '../models';
import { SubscriptionProductsResponse } from '../models';
import { SubscriptionVerificationRequest } from '../models';
import { SubscriptionVerificationResponse } from '../models';
/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List products, by coupon codes, if exists
         * @param {SubscriptionProductsRequest} body Request containing user&#x27;s coupon code, if exists.
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionProductsPost: async (body: SubscriptionProductsRequest, client?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling subscriptionProductsPost.');
            }
            const localVarPath = `/subscription/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (client !== undefined && client !== null) {
                localVarHeaderParameter['client'] = String(JSON.stringify(client));
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore a user's subscription
         * @param {SubscriptionVerificationRequest} body Request containing user&#x27;s store token
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionRestorePost: async (body: SubscriptionVerificationRequest, client?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling subscriptionRestorePost.');
            }
            const localVarPath = `/subscription/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (client !== undefined && client !== null) {
                localVarHeaderParameter['client'] = String(JSON.stringify(client));
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify if user is premium or not
         * @param {SubscriptionVerificationRequest} body RRequest containing user&#x27;s store token
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionVerifyPurchasePost: async (body: SubscriptionVerificationRequest, client?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling subscriptionVerifyPurchasePost.');
            }
            const localVarPath = `/subscription/verifyPurchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (client !== undefined && client !== null) {
                localVarHeaderParameter['client'] = String(JSON.stringify(client));
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List products, by coupon codes, if exists
         * @param {SubscriptionProductsRequest} body Request containing user&#x27;s coupon code, if exists.
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionProductsPost(body: SubscriptionProductsRequest, client?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionProductsResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionProductsPost(body, client, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Restore a user's subscription
         * @param {SubscriptionVerificationRequest} body Request containing user&#x27;s store token
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionRestorePost(body: SubscriptionVerificationRequest, client?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionVerificationResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionRestorePost(body, client, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Verify if user is premium or not
         * @param {SubscriptionVerificationRequest} body RRequest containing user&#x27;s store token
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionVerifyPurchasePost(body: SubscriptionVerificationRequest, client?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionVerificationResponse>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).subscriptionVerifyPurchasePost(body, client, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary List products, by coupon codes, if exists
         * @param {SubscriptionProductsRequest} body Request containing user&#x27;s coupon code, if exists.
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionProductsPost(body: SubscriptionProductsRequest, client?: number, options?: any): AxiosPromise<SubscriptionProductsResponse> {
            return SubscriptionApiFp(configuration).subscriptionProductsPost(body, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore a user's subscription
         * @param {SubscriptionVerificationRequest} body Request containing user&#x27;s store token
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionRestorePost(body: SubscriptionVerificationRequest, client?: number, options?: any): AxiosPromise<SubscriptionVerificationResponse> {
            return SubscriptionApiFp(configuration).subscriptionRestorePost(body, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify if user is premium or not
         * @param {SubscriptionVerificationRequest} body RRequest containing user&#x27;s store token
         * @param {number} [client] ios:1 - android:2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionVerifyPurchasePost(body: SubscriptionVerificationRequest, client?: number, options?: any): AxiosPromise<SubscriptionVerificationResponse> {
            return SubscriptionApiFp(configuration).subscriptionVerifyPurchasePost(body, client, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @summary List products, by coupon codes, if exists
     * @param {SubscriptionProductsRequest} body Request containing user&#x27;s coupon code, if exists.
     * @param {number} [client] ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionProductsPost(body: SubscriptionProductsRequest, client?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionProductsPost(body, client, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Restore a user's subscription
     * @param {SubscriptionVerificationRequest} body Request containing user&#x27;s store token
     * @param {number} [client] ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionRestorePost(body: SubscriptionVerificationRequest, client?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionRestorePost(body, client, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Verify if user is premium or not
     * @param {SubscriptionVerificationRequest} body RRequest containing user&#x27;s store token
     * @param {number} [client] ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionVerifyPurchasePost(body: SubscriptionVerificationRequest, client?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionVerifyPurchasePost(body, client, options).then((request) => request(this.axios, this.basePath));
    }
}

export const defaultCouponCodeData: any = {
  couponCode: "",
  expireDate: "2022-12-12T00:00:00",
  limit: 0,
  productIdForAndroid: "",
  basePlanIdForAndroid: "",
  offerIdForAndroid: "",
  productIdForIOS: "",
  offerIdForIOS: "",
};

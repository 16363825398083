



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { defaultArticleData } from '@/api/articles'
import { AppModule } from '@/store/modules/app'
import MaterialInput from '@/components/MaterialInput/index.vue'
import {
  CommentDropdown,
  PlatformDropdown,
  SourceUrlDropdown
} from '../example/components/Dropdown'
import {
  GenericBooleanResponse,
  GetUsersResponse,
  UserModel
} from '@/wwapi/models'
import { AdminApi } from '@/wwapi/apis/admin-api'
import { ITagView } from '@/store/modules/tags-view'

@Component({
  name: 'ArticleDetail',
  components: {
    CommentDropdown,
    PlatformDropdown,
    SourceUrlDropdown,
    MaterialInput
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean;
  private list: UserModel[] = [];
  private listLoading = true;
  private postForm = Object.assign({}, defaultArticleData);
  private loading = false;
  private userListOptions: any[] = [];
  private trainerId = '';
  private traineeIds: string[] = [];

  private tempTagView?: ITagView;
  private tinymceActive = true;

  get abstractContentLength() {
    return this.postForm.abstractContent.length
  }

  get lang() {
    return AppModule.language
  }

  private async sendRelationToServer() {
    const adminApi = new AdminApi()
    const response = await adminApi.userMobilityAddTrainerTraineeRelationPost({
      trainerId: this.trainerId,
      traineeIds: this.traineeIds
    })
    const responseModel: GenericBooleanResponse = response.data
    if (responseModel.data) {
      this.$notify({
        title: 'Success',
        message: 'Successfully added trainer/trainee relation',
        type: 'success',
        duration: 2000
      })
    }
  }

  // set and get is useful when the data
  // returned by the backend api is different from the frontend
  // e.g.: backend return => "2013-06-25 06:59:25"
  //       frontend need timestamp => 1372114765000
  get timestamp() {
    return +new Date(this.postForm.timestamp)
  }

  set timestamp(value) {
    this.postForm.timestamp = +new Date(value)
  }

  deactivated() {
    this.tinymceActive = false
  }

  activated() {
    this.tinymceActive = true
  }

  private async getRemoteUserList(name: string) {
    if (name.length < 4) {
      return
    }
    this.listLoading = true
    const adminApi = new AdminApi()
    const response = await adminApi.userListPost({
      text: name
    })
    const responseModel: GetUsersResponse = response.data
    const users: UserModel[] = responseModel.data
    if (users.length && users.length > 0) this.list = users
    else this.list = []
    this.userListOptions = users.map((v: any) => {
      const data = { userId: v.userId, email: v.email }
      return data
    })
  }
}

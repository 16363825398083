import { LocalizationKeyExportAllKeysResponse } from "./../models/localization-key-export-all-keys-response";
/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
import { AddCouponCodeRequest } from "../models";
import { AddCouponCodeResponse } from "../models";
import { AddTrainerTraineeRelationRequest } from "../models";
import { CheckUserEmailRequest } from "../models";
import { CouponCodesAndProductsResponse } from "../models";
import { EmptyRequest } from "../models";
import { ErrorModelResponse } from "../models";
import { GenericBooleanResponse } from "../models";
import { GetUsersRequest } from "../models";
import { GetUsersResponse } from "../models";
import { LocalizationKeyUpdateRequest } from "../models";
import { LocalizationKeyUpdateResponse } from "../models";
import { LocalizationTextDetailRequest } from "../models";
import { LocalizationTextsFilterRequest } from "../models";
import { LocalizationTextsForAdminResponse } from "../models";
import { UpdateCouponCodeRequest } from "../models";
import { UpdateCouponCodeResponse } from "../models";
import { UpdateUserRequest } from "../models";
import { UserGenericResponse } from "../models";
import { UserIdRequest } from "../models";
import { UserResponse } from "../models";
import { GetAdminPackagesResponseData } from "../models";
import { AdminPostPackageRequest } from "../models/admin-post-packages-request";
import { GetCompaniesResponseData } from "../models/get-companies-response";
import { PostCompanyRequest } from "../models/post-company-request";
import { GetFreeZonesResponseData } from "../models/get-freezones-response";
import { PostFreeZoneRequest } from "../models/post-freezone-request";
import { DeleteCompanyRequest } from "../models/delete-company-request";
import { DeleteFreeZoneRequest } from "../models/delete-freezone-request";
import { GetClientSettingsResponseData } from "../models/get-clientSettings-response";
import { GetAssistantConfigsResponseData } from "../models/get-assistantConfigs-response";
import { PostAssistantConfigRequest } from "../models/post-assistantConfig-request";
import { DeleteAssistantConfigRequest } from "../models/delete-assistantConfig-request";
import { GetCallEventsRequest } from "../models/get-callEvents-request";
import { GetCallEventsResponse } from "../models/get-callEvents-response";
import { GetRedisValueRequest } from "../models/get-redis-value-request";
import { GetRedisValueResponse } from "../models/get-redis-value-response";
import { SetRedisValueRequest } from "../models/set-redis-value-request";
import { SetRedisValueResponse } from "../models/set-redis-value-response";
import { GetSessionsRequest } from "../models/get-sessions-request";
import { GetSessionsResponse } from "../models/get-sessions-response";
import { GetSubjectsResponseData } from "../models/get-subjects-response";
import { GetBrandsResponseData } from "../models/get-brands-response";
import { PostSubjectRequest } from "../models/post-subject-request";
import { PostBrandRequest } from "../models/post-brand-request";
import { GetBrandSubjectsResponseData } from "../models/get-brand-subjects-response";
import { PostBrandSubjectRequest } from "../models/post-brand-subject-request";
import { DeleteBrandSubjectRequest } from "../models/delete-brand-subject-request";
import { GetBrandSubjectsRequest } from "../models/get-brand-subjects-request";
import { GetCompaniesRequest } from "../models/get-companies-request";
import { OtaFileResponse } from "../models/get-all-ota-files-response";

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get all localization keys
     * @param {EmptyRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationExportAllKeysPost: async (
      body: EmptyRequest,
      client?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling localizationExportAllKeysPost."
        );
      }
      const localVarPath = `/localization/exportAllKeys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      if (client !== undefined && client !== null) {
        localVarHeaderParameter["client"] = String(JSON.stringify(client));
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create and export client related files
     * @param {CheckUserEmailRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationExportClientArtifactsPost: async (
      body: CheckUserEmailRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling localizationExportClientArtifactsPost."
        );
      }
      const localVarPath = `/localization/exportClientArtifacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filters related localization keys
     * @param {LocalizationTextsFilterRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationFilterPost: async (
      body: LocalizationTextsFilterRequest,
      client?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling localizationFilterPost."
        );
      }
      const localVarPath = `/localization/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      if (client !== undefined && client !== null) {
        localVarHeaderParameter["client"] = String(JSON.stringify(client));
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all localization texts by key/andKey
     * @param {LocalizationTextDetailRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationGetDetailByKeyPost: async (
      body: LocalizationTextDetailRequest,
      client?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling localizationGetDetailByKeyPost."
        );
      }
      const localVarPath = `/localization/getDetailByKey`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      if (client !== undefined && client !== null) {
        localVarHeaderParameter["client"] = String(JSON.stringify(client));
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gathers all the key/value pairs
     * @param {EmptyRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationTextsForAdminPost: async (
      body: EmptyRequest,
      client?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling localizationTextsForAdminPost."
        );
      }
      const localVarPath = `/localization/textsForAdmin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      if (client !== undefined && client !== null) {
        localVarHeaderParameter["client"] = String(JSON.stringify(client));
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update localization key value
     * @param {LocalizationKeyUpdateRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationUpdateLocalizationKeyPost: async (
      body: LocalizationKeyUpdateRequest,
      client?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling localizationUpdateLocalizationKeyPost."
        );
      }
      const localVarPath = `/localization/updateLocalizationKey`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      if (client !== undefined && client !== null) {
        localVarHeaderParameter["client"] = String(JSON.stringify(client));
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    /**
    *
    * @summary List all ota files
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
    getAllOtaFiles: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/otafiles/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    /**
     *
     * @summary Add coupon code
     * @param {AddCouponCodeRequest} body Request containing newly added coupon codes and detailed information about
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionAddCouponCodePost: async (
      body: AddCouponCodeRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling subscriptionAddCouponCodePost."
        );
      }
      const localVarPath = `/subscription/addCouponCode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List products and coupon codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionGetCouponCodesAndProductsGet: async (
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/subscription/getCouponCodesAndProducts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update coupon code
     * @param {UpdateCouponCodeRequest} body Request containing newly edited coupon codes and detailed information about
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionUpdateCouponCodePost: async (
      body: UpdateCouponCodeRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling subscriptionUpdateCouponCodePost."
        );
      }
      const localVarPath = `/subscription/updateCouponCode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get full list of users
     * @param {GetUsersRequest} body Get users request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userListPost: async (
      body: GetUsersRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling userListPost."
        );
      }
      const localVarPath = `/user/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add trainer-trainee relation
     * @param {AddTrainerTraineeRelationRequest} body Empty reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userMobilityAddTrainerTraineeRelationPost: async (
      body: AddTrainerTraineeRelationRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling userMobilityAddTrainerTraineeRelationPost."
        );
      }
      const localVarPath = `/user/mobility/addTrainerTraineeRelation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Toggle selected user's beta user status (true,false)
     * @param {UserIdRequest} body Toggle user&#x27;s beta status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userToggleBetaUserPost: async (
      body: UserIdRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling userToggleBetaUserPost."
        );
      }
      const localVarPath = `/user/toggleBetaUser`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user information from admin panel.
     * @param {UpdateUserRequest} body Update user information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUpdateUserFromAdminPost: async (
      body: UpdateUserRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling userUpdateUserFromAdminPost."
        );
      }
      const localVarPath = `/user/updateUserFromAdmin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    userToggleCanUseConsult: async (
      body: UserIdRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling userUpdateUserFromAdminPost."
        );
      }
      const localVarPath = `/user/toggleCanUseConsult`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    getAdminPackages: async (options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined

      const localVarPath = `/admin/packages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    adminPostPackage: async (
      body: AdminPostPackageRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling userUpdateUserFromAdminPost."
        );
      }
      const localVarPath = `/admin/postPackage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    togglePackageActive: async (
      body: { id: string },
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling userUpdateUserFromAdminPost."
        );
      }
      const localVarPath = `/admin/togglePackage`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    getAssistantStatus: async (options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined

      const localVarPath = `/consult/getAssistantStatus`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    toggleAssistantStatus: async (
      body: EmptyRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/consult/toggleAssistantStatus`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    getCompanies: async (
      body: GetCompaniesRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      if (!body || body.includeIsActiveCondition === undefined) {
        throw new RequiredError(
          "body",
          "getCompanies çağrılırken body parametresi null veya tanımsızdı."
        );
      }

      const localVarPath = `/freezone/allFreezoneSponsorship`;
      const localVarUrlObj = new URL(localVarPath, "https://example.com");

      localVarUrlObj.searchParams.set(
        "includeIsActiveCondition",
        body.includeIsActiveCondition.toString()
      );

      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };

      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      console.log(localVarRequestOptions);

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    postCompany: async (
      body: PostCompanyRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling createFreezoneSponsorship."
        );
      }
      const localVarPath = `/freezone/createFreezoneSponsorship`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    updateCompany: async (
      body: PostCompanyRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateFreezoneSponsorship."
        );
      }
      const localVarPath = `/freezone/updateFreezoneSponsorship`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    deleteCompany: async (
      body: DeleteCompanyRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling deleteFreezoneSponsorship."
        );
      }
      const localVarPath = `/freezone/deleteFreezoneSponsorship`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    getFreeZones: async (options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined

      const localVarPath = `/freezone/allFreezonesWithSponsorship`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    postFreeZone: async (
      body: PostFreeZoneRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling createFreezone."
        );
      }
      const localVarPath = `/freezone/createFreezone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    updateFreeZone: async (
      body: PostFreeZoneRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateFreezone."
        );
      }
      const localVarPath = `/freezone/updateFreezone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    deleteFreeZone: async (
      body: DeleteFreeZoneRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateFreezone."
        );
      }
      const localVarPath = `/freezone/deleteFreezone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    getClientSettings: async (options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined

      const localVarPath = `/clientSettings/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    getAssistantConfigs: async (options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined

      const localVarPath = `/assistantConfig`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    postAssistantConfig: async (
      body: PostAssistantConfigRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling assistantConfig."
        );
      }
      const localVarPath = `/assistantConfig`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    updateAssistantConfig: async (
      body: PostAssistantConfigRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling assistantConfig."
        );
      }
      const localVarPath = `/assistantConfig`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    deleteAssistantConfig: async (
      body: DeleteAssistantConfigRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling assistantConfig."
        );
      }
      const localVarPath = `/assistantConfig`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    callEventListPost: async (
      body: GetCallEventsRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling userListPost."
        );
      }
      const localVarPath = `/admin/getUserConsultInformation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    getRedisValue: async (
      body: GetRedisValueRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      console.log(body);
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling assistantConfig."
        );
      }
      const localVarPath = `/config/getRedisValue`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    setRedisValue: async (
      body: SetRedisValueRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      console.log(body);
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling assistantConfig."
        );
      }
      const localVarPath = `/config/setRedisValue`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    sessionsListPost: async (
      body: GetSessionsRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      console.log(body);
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling assistantConfig."
        );
      }
      const localVarPath = `/elastic/sessions-search-to-elasticsearch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    getSubjects: async (options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined

      const localVarPath = `/consult/subjects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    createSubject: async (
      body: PostSubjectRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling createSubject."
        );
      }
      const localVarPath = `/consult/subjects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    updateSubject: async (
      body: PostSubjectRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateSubject."
        );
      }
      const localVarPath = `/consult/subjects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    getBrands: async (options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined

      const localVarPath = `/consult/brands`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    createBrand: async (
      body: PostSubjectRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling createBrand."
        );
      }
      const localVarPath = `/consult/brands`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    updateBrand: async (
      body: PostBrandRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateBrand."
        );
      }
      const localVarPath = `/consult/brands`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    getBrandSubjects: async (
      body: GetBrandSubjectsRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      if (!body || body.isActive === undefined) {
        throw new RequiredError(
          "body",
          "updateBrandSubject çağrılırken body parametresi null veya tanımsızdı."
        );
      }

      const localVarPath = `/consult/brand-subjects`;
      const localVarUrlObj = new URL(localVarPath, "https://example.com");

      localVarUrlObj.searchParams.set("isActive", body.isActive.toString());

      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };

      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      console.log(localVarRequestOptions);

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    updateBrandSubject: async (
      body: PostBrandSubjectRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateBrandSubject."
        );
      }
      const localVarPath = `/consult/brand-subjects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    createBrandSubject: async (
      body: PostBrandSubjectRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling createBrandSubject."
        );
      }
      const localVarPath = `/consult/brand-subjects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },

    deleteBrandSubject: async (
      body: DeleteBrandSubjectRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateFreezone."
        );
      }
      const localVarPath = `/consult/brand-subject`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};
/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all localization keys
     * @param {EmptyRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async localizationExportAllKeysPost(
      body: EmptyRequest,
      client?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LocalizationKeyExportAllKeysResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).localizationExportAllKeysPost(body, client, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create and export client related files
     * @param {CheckUserEmailRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async localizationExportClientArtifactsPost(
      body: CheckUserEmailRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GenericBooleanResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).localizationExportClientArtifactsPost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Filters related localization keys
     * @param {LocalizationTextsFilterRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async localizationFilterPost(
      body: LocalizationTextsFilterRequest,
      client?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LocalizationTextsForAdminResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).localizationFilterPost(body, client, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all localization texts by key/andKey
     * @param {LocalizationTextDetailRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async localizationGetDetailByKeyPost(
      body: LocalizationTextDetailRequest,
      client?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LocalizationTextsForAdminResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).localizationGetDetailByKeyPost(body, client, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Gathers all the key/value pairs
     * @param {EmptyRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async localizationTextsForAdminPost(
      body: EmptyRequest,
      client?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LocalizationTextsForAdminResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).localizationTextsForAdminPost(body, client, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update localization key value
     * @param {LocalizationKeyUpdateRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async localizationUpdateLocalizationKeyPost(
      body: LocalizationKeyUpdateRequest,
      client?: number,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<LocalizationKeyUpdateResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).localizationUpdateLocalizationKeyPost(body, client, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    /**
    *
    * @summary List all ota files
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
    async getAllOtaFiles(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<OtaFileResponse[]>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getAllOtaFiles(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    /**
     *
     * @summary Add coupon code
     * @param {AddCouponCodeRequest} body Request containing newly added coupon codes and detailed information about
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionAddCouponCodePost(
      body: AddCouponCodeRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AddCouponCodeResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).subscriptionAddCouponCodePost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List products and coupon codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionGetCouponCodesAndProductsGet(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CouponCodesAndProductsResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).subscriptionGetCouponCodesAndProductsGet(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update coupon code
     * @param {UpdateCouponCodeRequest} body Request containing newly edited coupon codes and detailed information about
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionUpdateCouponCodePost(
      body: UpdateCouponCodeRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UpdateCouponCodeResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).subscriptionUpdateCouponCodePost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get full list of users
     * @param {GetUsersRequest} body Get users request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userListPost(
      body: GetUsersRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetUsersResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).userListPost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Add trainer-trainee relation
     * @param {AddTrainerTraineeRelationRequest} body Empty reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userMobilityAddTrainerTraineeRelationPost(
      body: AddTrainerTraineeRelationRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GenericBooleanResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).userMobilityAddTrainerTraineeRelationPost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Toggle selected user's beta user status (true,false)
     * @param {UserIdRequest} body Toggle user&#x27;s beta status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userToggleBetaUserPost(
      body: UserIdRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).userToggleBetaUserPost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update user information from admin panel.
     * @param {UpdateUserRequest} body Update user information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUpdateUserFromAdminPost(
      body: UpdateUserRequest,
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).userUpdateUserFromAdminPost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Toggle selected user's beta user status (true,false)
     * @param {UserIdRequest} body Toggle user&#x27;s beta status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userToggleCanUseConsult(
      body: UserIdRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).userToggleCanUseConsult(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    async getAdminPackages(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetAdminPackagesResponseData>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getAdminPackages(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Toggle selected user's beta user status (true,false)
     * @param {AdminPostPackageRequest} body Toggle user&#x27;s beta status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPostPackage(
      body: AdminPostPackageRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).adminPostPackage(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Toggle selected user's beta user status (true,false)
     * @param {id: string} body Toggle user&#x27;s beta status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async togglePackageActive(
      body: { id: string },
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).togglePackageActive(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    async getAssistantStatus(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getAssistantStatus(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    async toggleAsistantStatus(
      body: EmptyRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).toggleAssistantStatus(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    async getCompanies(
      body: GetCompaniesRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCompaniesResponseData>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getCompanies(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async postCompany(
      body: PostCompanyRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).postCompany(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async updateCompany(
      body: PostCompanyRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).updateCompany(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async deleteCompany(
      body: DeleteCompanyRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).deleteCompany(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async getFreeZones(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetFreeZonesResponseData>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getFreeZones(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async postFreeZone(
      body: PostFreeZoneRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).postFreeZone(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async updateFreeZone(
      body: PostFreeZoneRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).updateFreeZone(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async deleteFreeZone(
      body: DeleteFreeZoneRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).deleteFreeZone(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async getClientSettings(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetClientSettingsResponseData>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getClientSettings(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async getAssistantConfigs(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetAssistantConfigsResponseData>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getAssistantConfigs(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async postAssistantConfig(
      body: PostAssistantConfigRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).postAssistantConfig(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async updateAssistantConfig(
      body: PostAssistantConfigRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).updateAssistantConfig(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async deleteAssistantConfig(
      body: DeleteAssistantConfigRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).deleteAssistantConfig(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async callEventListPost(
      body: GetCallEventsRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCallEventsResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).callEventListPost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async getRedisValue(
      body: GetRedisValueRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetRedisValueResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getRedisValue(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async setRedisValue(
      body: SetRedisValueRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SetRedisValueResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).setRedisValue(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async sessionsListPost(
      body: GetSessionsRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetSessionsResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).sessionsListPost(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async getSubjects(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetSubjectsResponseData>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getSubjects(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async createSubject(
      body: PostSubjectRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).createSubject(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async updateSubject(
      body: PostSubjectRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).updateSubject(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async getBrands(
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetBrandsResponseData>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getBrands(options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async createBrand(
      body: PostBrandRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).createBrand(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async updateBrand(
      body: PostBrandRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).updateBrand(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    async getBrandSubjects(
      body: GetBrandSubjectsRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetBrandSubjectsResponseData>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).getBrandSubjects(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async updateBrandSubject(
      body: PostBrandSubjectRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).updateBrandSubject(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async createBrandSubject(
      body: PostBrandSubjectRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).createBrandSubject(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },

    async deleteBrandSubject(
      body: DeleteBrandSubjectRequest,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserGenericResponse>
    > {
      const localVarAxiosArgs = await AdminApiAxiosParamCreator(
        configuration
      ).deleteBrandSubject(body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get all localization keys
     * @param {EmptyRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationExportAllKeysPost(
      body: EmptyRequest,
      client?: number,
      options?: any
    ): AxiosPromise<LocalizationKeyExportAllKeysResponse> {
      return AdminApiFp(configuration)
        .localizationExportAllKeysPost(body, client, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create and export client related files
     * @param {CheckUserEmailRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationExportClientArtifactsPost(
      body: CheckUserEmailRequest,
      options?: any
    ): AxiosPromise<GenericBooleanResponse> {
      return AdminApiFp(configuration)
        .localizationExportClientArtifactsPost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Filters related localization keys
     * @param {LocalizationTextsFilterRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationFilterPost(
      body: LocalizationTextsFilterRequest,
      client?: number,
      options?: any
    ): AxiosPromise<LocalizationTextsForAdminResponse> {
      return AdminApiFp(configuration)
        .localizationFilterPost(body, client, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all localization texts by key/andKey
     * @param {LocalizationTextDetailRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationGetDetailByKeyPost(
      body: LocalizationTextDetailRequest,
      client?: number,
      options?: any
    ): AxiosPromise<LocalizationTextsForAdminResponse> {
      return AdminApiFp(configuration)
        .localizationGetDetailByKeyPost(body, client, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Gathers all the key/value pairs
     * @param {EmptyRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationTextsForAdminPost(
      body: EmptyRequest,
      client?: number,
      options?: any
    ): AxiosPromise<LocalizationTextsForAdminResponse> {
      return AdminApiFp(configuration)
        .localizationTextsForAdminPost(body, client, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update localization key value
     * @param {LocalizationKeyUpdateRequest} body
     * @param {number} [client] ui:0 - ios:1 - android:2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    localizationUpdateLocalizationKeyPost(
      body: LocalizationKeyUpdateRequest,
      client?: number,
      options?: any
    ): AxiosPromise<LocalizationKeyUpdateResponse> {
      return AdminApiFp(configuration)
        .localizationUpdateLocalizationKeyPost(body, client, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add coupon code
     * @param {AddCouponCodeRequest} body Request containing newly added coupon codes and detailed information about
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionAddCouponCodePost(
      body: AddCouponCodeRequest,
      options?: any
    ): AxiosPromise<AddCouponCodeResponse> {
      return AdminApiFp(configuration)
        .subscriptionAddCouponCodePost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List products and coupon codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionGetCouponCodesAndProductsGet(
      options?: any
    ): AxiosPromise<CouponCodesAndProductsResponse> {
      return AdminApiFp(configuration)
        .subscriptionGetCouponCodesAndProductsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update coupon code
     * @param {UpdateCouponCodeRequest} body Request containing newly edited coupon codes and detailed information about
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionUpdateCouponCodePost(
      body: UpdateCouponCodeRequest,
      options?: any
    ): AxiosPromise<UpdateCouponCodeResponse> {
      return AdminApiFp(configuration)
        .subscriptionUpdateCouponCodePost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get full list of users
     * @param {GetUsersRequest} body Get users request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userListPost(
      body: GetUsersRequest,
      options?: any
    ): AxiosPromise<GetUsersResponse> {
      return AdminApiFp(configuration)
        .userListPost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add trainer-trainee relation
     * @param {AddTrainerTraineeRelationRequest} body Empty reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userMobilityAddTrainerTraineeRelationPost(
      body: AddTrainerTraineeRelationRequest,
      options?: any
    ): AxiosPromise<GenericBooleanResponse> {
      return AdminApiFp(configuration)
        .userMobilityAddTrainerTraineeRelationPost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Toggle selected user's beta user status (true,false)
     * @param {UserIdRequest} body Toggle user&#x27;s beta status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userToggleBetaUserPost(
      body: UserIdRequest,
      options?: any
    ): AxiosPromise<UserGenericResponse> {
      return AdminApiFp(configuration)
        .userToggleBetaUserPost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update user information from admin panel.
     * @param {UpdateUserRequest} body Update user information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUpdateUserFromAdminPost(
      body: UpdateUserRequest,
      options?: any
    ): AxiosPromise<UserResponse> {
      return AdminApiFp(configuration)
        .userUpdateUserFromAdminPost(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update user information from admin panel.
     * @param {EmptyRequest} body Update user information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdminPackages(options?: any): AxiosPromise<UserResponse> {
      return AdminApiFp(configuration)
        .userUpdateUserFromAdminPost(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   *
   * @summary Get all localization keys
   * @param {EmptyRequest} body
   * @param {number} [client] ui:0 - ios:1 - android:2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public localizationExportAllKeysPost(
    body: EmptyRequest,
    client?: number,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .localizationExportAllKeysPost(body, client, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Create and export client related files
   * @param {CheckUserEmailRequest} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public localizationExportClientArtifactsPost(
    body: CheckUserEmailRequest,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .localizationExportClientArtifactsPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Filters related localization keys
   * @param {LocalizationTextsFilterRequest} body
   * @param {number} [client] ui:0 - ios:1 - android:2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public localizationFilterPost(
    body: LocalizationTextsFilterRequest,
    client?: number,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .localizationFilterPost(body, client, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get all localization texts by key/andKey
   * @param {LocalizationTextDetailRequest} body
   * @param {number} [client] ui:0 - ios:1 - android:2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public localizationGetDetailByKeyPost(
    body: LocalizationTextDetailRequest,
    client?: number,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .localizationGetDetailByKeyPost(body, client, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Gathers all the key/value pairs
   * @param {EmptyRequest} body
   * @param {number} [client] ui:0 - ios:1 - android:2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public localizationTextsForAdminPost(
    body: EmptyRequest,
    client?: number,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .localizationTextsForAdminPost(body, client, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update localization key value
   * @param {LocalizationKeyUpdateRequest} body
   * @param {number} [client] ui:0 - ios:1 - android:2
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public localizationUpdateLocalizationKeyPost(
    body: LocalizationKeyUpdateRequest,
    client?: number,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .localizationUpdateLocalizationKeyPost(body, client, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List all ota files
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllOtaFiles(options?: any) {
    return AdminApiFp(this.configuration)
      .getAllOtaFiles(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add coupon code
   * @param {AddCouponCodeRequest} body Request containing newly added coupon codes and detailed information about
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public subscriptionAddCouponCodePost(
    body: AddCouponCodeRequest,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .subscriptionAddCouponCodePost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary List products and coupon codes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public subscriptionGetCouponCodesAndProductsGet(options?: any) {
    return AdminApiFp(this.configuration)
      .subscriptionGetCouponCodesAndProductsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update coupon code
   * @param {UpdateCouponCodeRequest} body Request containing newly edited coupon codes and detailed information about
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public subscriptionUpdateCouponCodePost(
    body: UpdateCouponCodeRequest,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .subscriptionUpdateCouponCodePost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get full list of users
   * @param {GetUsersRequest} body Get users request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public userListPost(body: GetUsersRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .userListPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Add trainer-trainee relation
   * @param {AddTrainerTraineeRelationRequest} body Empty reqeust
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public userMobilityAddTrainerTraineeRelationPost(
    body: AddTrainerTraineeRelationRequest,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .userMobilityAddTrainerTraineeRelationPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Toggle selected user's beta user status (true,false)
   * @param {UserIdRequest} body Toggle user&#x27;s beta status request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public userToggleBetaUserPost(body: UserIdRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .userToggleBetaUserPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update user information from admin panel.
   * @param {UpdateUserRequest} body Update user information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public userUpdateUserFromAdminPost(body: UpdateUserRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .userUpdateUserFromAdminPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Toggle selected user's beta user status (true,false)
   * @param {UserIdRequest} body Toggle user&#x27;s beta status request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public userToggleCanUseConsult(body: UserIdRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .userToggleCanUseConsult(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Toggle selected user's beta user status (true,false)
   * @param {EmptyRequest} body Toggle user&#x27;s beta status request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAdminPackages(options?: any) {
    return AdminApiFp(this.configuration)
      .getAdminPackages(options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update user information from admin panel.
   * @param {AdminPostPackageRequest} body Update user information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminPostPackage(body: AdminPostPackageRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .adminPostPackage(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update user information from admin panel.
   * @param {AdminPostPackageRequest} body Update user information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public togglePackageActive(body: { id: string }, options?: any) {
    return AdminApiFp(this.configuration)
      .togglePackageActive(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update user information from admin panel.
   * @param {EmptyRequest} body Update user information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAssistantStatus(options?: any) {
    return AdminApiFp(this.configuration)
      .getAssistantStatus(options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update user information from admin panel.
   * @param {EmptyRequest} body Update user information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public toggleAsistantStatus(body: EmptyRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .toggleAsistantStatus(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Company List
   * @param {EmptyRequest} body Company List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getCompanies(body: GetCompaniesRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .getCompanies(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create company information from admin panel.
   * @param {PostCompanyRequest} body Create company information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postCompany(body: PostCompanyRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .postCompany(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete company information from admin panel.
   * @param {PostCompanyRequest} body Delete company information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public deleteCompany(body: DeleteCompanyRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .deleteCompany(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update company information from admin panel.
   * @param {PostCompanyRequest} body Update company information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateCompany(body: PostCompanyRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .updateCompany(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get FreeZone List
   * @param {EmptyRequest} body FreeZone List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getFreeZones(options?: any) {
    return AdminApiFp(this.configuration)
      .getFreeZones(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create freeZone information from admin panel.
   * @param {PostFreeZoneRequest} body Create freeZone information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public postFreeZone(body: PostFreeZoneRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .postFreeZone(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update freeZone information from admin panel.
   * @param {PostFreeZoneRequest} body Create freeZone information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateFreeZone(body: PostFreeZoneRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .updateFreeZone(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete freeZone information from admin panel.
   * @param {DeleteFreeZoneRequest} body Delete freeZone information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public deleteFreeZone(body: DeleteFreeZoneRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .deleteFreeZone(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get ClientSettings
   * @param {EmptyRequest} body ClientSetting List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientSettings(options?: any) {
    return AdminApiFp(this.configuration)
      .getClientSettings(options)
      .then((request) => request(this.axios, this.basePath));
  }

  public getAssistantConfigs(options?: any) {
    return AdminApiFp(this.configuration)
      .getAssistantConfigs(options)
      .then((request) => request(this.axios, this.basePath));
  }

  public postAssistantConfig(body: PostAssistantConfigRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .postAssistantConfig(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public updateAssistantConfig(
    body: PostAssistantConfigRequest,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .updateAssistantConfig(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  public deleteAssistantConfig(
    body: DeleteAssistantConfigRequest,
    options?: any
  ) {
    return AdminApiFp(this.configuration)
      .deleteAssistantConfig(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public callEventListPost(body: GetCallEventsRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .callEventListPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public getRedisValue(body: GetRedisValueRequest, options?: any) {
    console.log(body);
    return AdminApiFp(this.configuration)
      .getRedisValue(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public setRedisValue(body: SetRedisValueRequest, options?: any) {
    console.log(body);
    return AdminApiFp(this.configuration)
      .setRedisValue(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  public sessionsListPost(body: GetSessionsRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .sessionsListPost(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Subject List
   * @param {EmptyRequest} body Subject List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getSubjects(options?: any) {
    return AdminApiFp(this.configuration)
      .getSubjects(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create subject information from admin panel.
   * @param {PostSubjectRequest} body Create subject information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createSubject(body: PostSubjectRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .createSubject(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update subject information from admin panel.
   * @param {PostSubjectRequest} body Update subject information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateSubject(body: PostSubjectRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .updateSubject(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Brand List
   * @param {EmptyRequest} body Brand List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getBrands(options?: any) {
    return AdminApiFp(this.configuration)
      .getBrands(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create brand information from admin panel.
   * @param {PostBrandRequest} body Create brand information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createBrand(body: PostBrandRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .createBrand(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update brand information from admin panel.
   * @param {PostBrandRequest} body Update brand information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateBrand(body: PostBrandRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .updateBrand(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Brand Subject List
   * @param {EmptyRequest} body Brand Subject List
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getBrandSubjects(body: GetBrandSubjectsRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .getBrandSubjects(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Brand Subject information from admin panel.
   * @param {PostBrandSubjectRequest} body Create Brand Subject information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateBrandSubject(body: PostBrandSubjectRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .updateBrandSubject(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Brand Subject information from admin panel.
   * @param {PostBrandSubjectRequest} body Create Brand Subject information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createBrandSubject(body: PostBrandSubjectRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .createBrandSubject(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Brand Subject information from admin panel.
   * @param {DeleteFreeZoneRequest} body Delete Brand Subject information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public deleteBrandSubject(body: DeleteBrandSubjectRequest, options?: any) {
    return AdminApiFp(this.configuration)
      .deleteBrandSubject(body, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

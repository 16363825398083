



















































































































import { Component, Vue } from "vue-property-decorator";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { cloneDeep } from "lodash";
import { Form } from "element-ui";
import { GetBrandsResponse } from "@/wwapi/models/get-brands-response";
import { defaultBrandData } from "../../defaultModels/IBrandData";

@Component({
  name: "BrandList",
})
export default class extends Vue {
  private list: GetBrandsResponse[] = [];
  private showFullDescription: Array<any> = [];
  private listLoading = true;
  private dialogFormVisible = false;
  private deleteConfirmationVisible = false;
  private deleteItemId = null;
  private dialogStatus = "";
  private tempBrandData = defaultBrandData;
  private textMap = {
    update: "Edit",
    create: "Create",
  };

  created() {
    this.fetchData();
  }

  private async fetchData() {
    this.listLoading = true;
    const adminApi = new AdminApi();
    const response = await adminApi.getBrands();
    console.log(response);
    if (response.data.data.length) this.list = response.data.data;
    else this.list = [];
    console.log(
      "data returned from getBrands" + JSON.stringify(response.data.data)
    );
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  private handleUpdate(row: any) {
    this.tempBrandData = Object.assign({}, row);
    this.dialogStatus = "update";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private updateData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempBrandData);
        const { data } = await adminApi.updateBrand({
          id: tempData.id,
          name: tempData.name,
          isActive: tempData.isActive,
        });
        const index = this.list.findIndex((v: any) => v.id === tempData.id);
        if (data.status.code === "success") {
          if (tempData.isActive === false) {
            this.list.splice(index, 1);
          } else {
            this.list.splice(index, 1, tempData);
          }
          this.dialogFormVisible = false;
          this.$notify({
            title: "Success",
            message: "Successfully update the brand",
            type: "success",
            duration: 2000,
          });
        }
      }
    });
  }

  private handleCreate() {
    this.tempBrandData = cloneDeep(defaultBrandData);
    this.dialogStatus = "create";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private createData() {
    const adminApi = new AdminApi();

    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempBrandData);
        const { data } = await adminApi.createBrand({
          name: tempData.name,
          isActive: tempData.isActive,
        });
        if (data && data.data) {
          this.list = [...this.list, data.data];
        }
        this.dialogFormVisible = false;
        this.$notify({
          title: "Success",
          message: "Successfully added new brand",
          type: "success",
          duration: 2000,
        });
      }
    });
  }

  private async toggleBrandActive(row: any, isActive: boolean) {
    const adminApi = new AdminApi();
    const { data } = await adminApi.updateBrand({
      id: row.id,
      name: row.name,
      isActive: isActive,
    });
    if (data.status.code === "success") {
      if (!isActive) {
        const index = this.list.findIndex((v: any) => v.id === data.data.id);
        this.list.splice(index, 1);
      }
      this.$notify({
        title: "Success",
        message: "Successfully brand status changed",
        type: "success",
        duration: 2000,
      });
    }
  }
}

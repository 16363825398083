export const defaultPackageData: any = {
  id: "",
  name: "",
  description: "",
  buttonText: "",
  code: "",
  platform: "",
  type: "",
  order: "",
  active: false,
  // updatedDate: new Date(),
  // createdDate: new Date(),
  packageDurationInSeconds: 0,
  isFirstSubscription: false,
  title: "",
};

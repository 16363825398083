/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance, AxiosError } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BASE_PATH_V3, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ChangePasswordRequest } from '../models';
import { ChangePasswordResponse } from '../models';
import { CheckUserEmailRequest } from '../models';
import { CheckUserPhoneRequest } from '../models';
import { CheckUserResponse } from '../models';
import { EmptyRequest } from '../models';
import { ErrorModelResponse } from '../models';
import { ForgotPasswordRequest } from '../models';
import { ForgotPasswordResponse } from '../models';
import { GetUsersResponse } from '../models';
import { MatchCaneWithUserRequest } from '../models';
import { MobilityUpdateMobilityTrainingAgreementBody } from '../models';
import { SigninEmailRequest } from '../models';
import { SigninFirebaseRequest } from '../models';
import { SignoutResponse } from '../models';
import { SignupEmailRequest } from '../models';
import { UpdateUserRequest } from '../models';
import { UserResponse } from '../models';
import { SigninJustEmailRequest } from '../models/signin-just-email-request';
import { SigninJustPhoneRequest } from '../models/signin-just-phone-request';
import { ValidateSmsCodeRequest } from '../models/validate-sms-code-request';
import { ValidateEmailCodeRequest } from '../models/validate-email-code-request';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks if the user with provided email exists.
         * @param {CheckUserEmailRequest} body Checks if the user has already an account on our system-with email provided.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCheckUserEmailPost: async (body: CheckUserEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authCheckUserEmailPost.');
            }
            const localVarPath = `/auth/checkUserEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the user's phone number exists.
         * @param {CheckUserPhoneRequest} body Check if the user has already an account on our system-with phone number provided.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCheckUserPhoneNumberPost: async (body: CheckUserPhoneRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authCheckUserPhoneNumberPost.');
            }
            const localVarPath = `/auth/checkUserPhoneNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gathers the information about the authenticated user. Gets the user info from token provided inside the authorization header.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGetUserInfoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/getUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign-in user for the system
         * @param {SigninEmailRequest} body Sign-in request of the user we want to sign in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSigninEmailPost: async (body: SigninEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authSigninEmailPost.');
            }
            const localVarPath = `/auth/signin/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            console.log(localVarUrlObj.pathname);
            console.log(localVarUrlObj.search);
            console.log(localVarUrlObj.hash);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },

        /**
        * 
        * @summary Sign-in user for the system
        * @param {SigninJustEmailRequest} body Sign-in request of the user we want to sign in
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        authSigninSendEmailCode: async (body: SigninJustEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authSigninEmailPost.');
            }
            const localVarPath = `/auth/signin/admin-signin-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            console.log(localVarUrlObj.pathname);
            console.log(localVarUrlObj.search);
            console.log(localVarUrlObj.hash);

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
        * 
        * @summary Sign-in user for the system
        * @param {SigninJustPhoneRequest} body Sign-in request of the user we want to sign in
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        authSigninSendSmsCode: async (body: SigninJustPhoneRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authSigninPhone.');
            }
            const localVarPath = `/auth/signin/admin-signin-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
        * 
        * @summary Sign-in user for the system
        * @param {ValidateEmailCodeRequest} body Sign-in request of the user we want to sign in
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        authSigninValidateEmailCode: async (body: ValidateEmailCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authSigninEmailPost.');
            }
            const localVarPath = `/auth/validateAssistantEmailCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
        * 
        * @summary Sign-in user for the system
        * @param {ValidateSmsCodeRequest} body Sign-in request of the user we want to sign in
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        authSigninValidateSmsCode: async (body: ValidateSmsCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authSigninPhonePost.');
            }
            const localVarPath = `/auth/validateAssistantSmsCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign-in user via Firebase Authentication for the system
         * @param {SigninFirebaseRequest} body Sign-in request via **Firebase Authentication** of the user we want to sign in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSigninFirebasePost: async (body: SigninFirebaseRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authSigninFirebasePost.');
            }
            const localVarPath = `/auth/signin/firebase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign-out user. This method deletes users auth token from Redis Cache.
         * @param {EmptyRequest} body Empty Sign-out request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignoutPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authSignoutPost.');
            }
            const localVarPath = `/auth/signout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign-up user for the system.
         * @param {SignupEmailRequest} body Sign-up request of the user we want to sign up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupEmailPost: async (body: SignupEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling authSignupEmailPost.');
            }
            const localVarPath = `/auth/signup/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change user password.
         * @param {ChangePasswordRequest} body Change user password.
         * @param {string} changepasswordsource Send &#x27;forgot&#x27; for forgot password, otherwise leave it &amp;lt;empty&amp;gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userChangePasswordPost: async (body: ChangePasswordRequest, changepasswordsource: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling userChangePasswordPost.');
            }
            // verify required parameter 'changepasswordsource' is not null or undefined
            if (changepasswordsource === null || changepasswordsource === undefined) {
                throw new RequiredError('changepasswordsource', 'Required parameter changepasswordsource was null or undefined when calling userChangePasswordPost.');
            }
            const localVarPath = `/user/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (changepasswordsource !== undefined && changepasswordsource !== null) {
                localVarHeaderParameter['changepasswordsource'] = String(changepasswordsource);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Forgot user password.
         * @param {ForgotPasswordRequest} body Forgot user password.
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userForgotPasswordPost: async (body: ForgotPasswordRequest, contentLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling userForgotPasswordPost.');
            }
            const localVarPath = `/user/forgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Match a cane with the logged-in user, drop all link between other users and this cane
         * @param {MatchCaneWithUserRequest} body Match cane with caneId and user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMatchCaneWithUserPost: async (body: MatchCaneWithUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling userMatchCaneWithUserPost.');
            }
            const localVarPath = `/user/matchCaneWithUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trainee info of a trainer
         * @param {EmptyRequest} body Empty reqeust
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMobilityGetTraineesOfTrainerPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling userMobilityGetTraineesOfTrainerPost.');
            }
            const localVarPath = `/user/mobility/getTraineesOfTrainer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user's mobility training agreement.
         * @param {MobilityUpdateMobilityTrainingAgreementBody} body Update user&#x27;s mobility training aggreement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMobilityUpdateMobilityTrainingAgreementPost: async (body: MobilityUpdateMobilityTrainingAgreementBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling userMobilityUpdateMobilityTrainingAgreementPost.');
            }
            const localVarPath = `/user/mobility/updateMobilityTrainingAgreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user's mobility training information.
         * @param {UpdateUserRequest} body Update user&#x27;s mobility training information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMobilityUpdateMobilityTrainingInformationPost: async (body: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling userMobilityUpdateMobilityTrainingInformationPost.');
            }
            const localVarPath = `/user/mobility/updateMobilityTrainingInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user's mobility information.
         * @param {UpdateUserRequest} body Update user&#x27;s mobility information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMobilityUpdatePost: async (body: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling userMobilityUpdatePost.');
            }
            const localVarPath = `/user/mobility/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user information.
         * @param {UpdateUserRequest} body Update user information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePost: async (body: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling userUpdatePost.');
            }
            const localVarPath = `/user/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks if the user with provided email exists.
         * @param {CheckUserEmailRequest} body Checks if the user has already an account on our system-with email provided.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authCheckUserEmailPost(body: CheckUserEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckUserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authCheckUserEmailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Checks if the user's phone number exists.
         * @param {CheckUserPhoneRequest} body Check if the user has already an account on our system-with phone number provided.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authCheckUserPhoneNumberPost(body: CheckUserPhoneRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckUserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authCheckUserPhoneNumberPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gathers the information about the authenticated user. Gets the user info from token provided inside the authorization header.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authGetUserInfoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authGetUserInfoGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sign-in user for the system
         * @param {SigninEmailRequest} body Sign-in request of the user we want to sign in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSigninEmailPost(body: SigninEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authSigninEmailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
        * 
        * @summary Sign-in user for the system
        * @param {SigninJustEmailRequest} body Sign-in request of the user we want to sign in
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        async authSigninSendEmailCode(body: SigninJustEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authSigninSendEmailCode(body, options);
            const apiUrl = BASE_PATH_V3 + localVarAxiosArgs.url;
            return async (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH_V3) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: apiUrl };
                try {
                    return await axios.request(axiosRequestArgs);
                } catch (error) {
                    const axiosError = error as AxiosError;
                    if (axiosError.response) {
                        // Sunucudan gelen hata kodunu ve mesajı kontrol et
                        if (axiosError.response.status === 400) {
                            if (axiosError.response.data && axiosError.response.data.message === "pop_login_phone_invalid_user_type") {
                                throw new Error("Özel hata mesajı: Kullanıcı tipi geçersiz.");
                            } else {
                                throw new Error("Özel hata mesajı: Geçersiz istek.");
                            }
                        } else if (axiosError.response.status === 401) {
                            throw new Error("Özel hata mesajı: Yetkisiz erişim.");
                        } else {
                            throw new Error(`Hata: ${axiosError.response.status}`);
                        }
                    } else {
                        throw new Error("Bir hata oluştu: " + axiosError.message);
                    }
                }
            };
        },
        /**
        * 
        * @summary Sign-in user for the system
        * @param {SigninJustPhoneRequest} body Sign-in request of the user we want to sign in
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        async authSigninSendSmsCode(body: SigninJustPhoneRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authSigninSendSmsCode(body, options);
            const apiUrl = BASE_PATH_V3 + localVarAxiosArgs.url;
            return async (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH_V3) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: apiUrl };
                try {
                    return await axios.request(axiosRequestArgs);
                } catch (error) {
                    const axiosError = error as AxiosError;
                    if (axiosError.response) {
                        // Sunucudan gelen hata kodunu ve mesajı kontrol edin
                        return Promise.reject(axiosError.response.data);
                    } else {
                        return Promise.reject(new Error("Bir hata oluştu: " + axiosError.message));
                    }
                }
            };
        },
        /**
        * 
        * @summary Validate user sms or email code for login to the system
        * @param {ValidateSmsCodeRequest} body Sign-in request of the user we want to sign in
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        async authSigninValidateSmsCode(body: ValidateSmsCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authSigninValidateSmsCode(body, options);
            const apiUrl = BASE_PATH_V3 + localVarAxiosArgs.url;
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH_V3) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: apiUrl };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validate user sms or email code for login to the system
         * @param {ValidateEmailCodeRequest} body Sign-in request of the user we want to sign in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSigninValidateEmailCode(body: ValidateEmailCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authSigninValidateEmailCode(body, options);
            const apiUrl = BASE_PATH_V3 + localVarAxiosArgs.url;
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH_V3) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: apiUrl };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sign-in user via Firebase Authentication for the system
         * @param {SigninFirebaseRequest} body Sign-in request via **Firebase Authentication** of the user we want to sign in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSigninFirebasePost(body: SigninFirebaseRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authSigninFirebasePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sign-out user. This method deletes users auth token from Redis Cache.
         * @param {EmptyRequest} body Empty Sign-out request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignoutPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignoutResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authSignoutPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sign-up user for the system.
         * @param {SignupEmailRequest} body Sign-up request of the user we want to sign up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignupEmailPost(body: SignupEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authSignupEmailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change user password.
         * @param {ChangePasswordRequest} body Change user password.
         * @param {string} changepasswordsource Send &#x27;forgot&#x27; for forgot password, otherwise leave it &amp;lt;empty&amp;gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userChangePasswordPost(body: ChangePasswordRequest, changepasswordsource: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userChangePasswordPost(body, changepasswordsource, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Forgot user password.
         * @param {ForgotPasswordRequest} body Forgot user password.
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userForgotPasswordPost(body: ForgotPasswordRequest, contentLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForgotPasswordResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userForgotPasswordPost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Match a cane with the logged-in user, drop all link between other users and this cane
         * @param {MatchCaneWithUserRequest} body Match cane with caneId and user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMatchCaneWithUserPost(body: MatchCaneWithUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userMatchCaneWithUserPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get trainee info of a trainer
         * @param {EmptyRequest} body Empty reqeust
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMobilityGetTraineesOfTrainerPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userMobilityGetTraineesOfTrainerPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user's mobility training agreement.
         * @param {MobilityUpdateMobilityTrainingAgreementBody} body Update user&#x27;s mobility training aggreement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMobilityUpdateMobilityTrainingAgreementPost(body: MobilityUpdateMobilityTrainingAgreementBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userMobilityUpdateMobilityTrainingAgreementPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user's mobility training information.
         * @param {UpdateUserRequest} body Update user&#x27;s mobility training information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMobilityUpdateMobilityTrainingInformationPost(body: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userMobilityUpdateMobilityTrainingInformationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user's mobility information.
         * @param {UpdateUserRequest} body Update user&#x27;s mobility information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMobilityUpdatePost(body: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userMobilityUpdatePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user information.
         * @param {UpdateUserRequest} body Update user information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdatePost(body: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).userUpdatePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Checks if the user with provided email exists.
         * @param {CheckUserEmailRequest} body Checks if the user has already an account on our system-with email provided.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCheckUserEmailPost(body: CheckUserEmailRequest, options?: any): AxiosPromise<CheckUserResponse> {
            return UserApiFp(configuration).authCheckUserEmailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks if the user's phone number exists.
         * @param {CheckUserPhoneRequest} body Check if the user has already an account on our system-with phone number provided.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCheckUserPhoneNumberPost(body: CheckUserPhoneRequest, options?: any): AxiosPromise<CheckUserResponse> {
            return UserApiFp(configuration).authCheckUserPhoneNumberPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gathers the information about the authenticated user. Gets the user info from token provided inside the authorization header.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGetUserInfoGet(options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).authGetUserInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign-in user for the system
         * @param {SigninEmailRequest} body Sign-in request of the user we want to sign in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSigninEmailPost(body: SigninEmailRequest, options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).authSigninEmailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign-in user via Firebase Authentication for the system
         * @param {SigninFirebaseRequest} body Sign-in request via **Firebase Authentication** of the user we want to sign in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSigninFirebasePost(body: SigninFirebaseRequest, options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).authSigninFirebasePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign-out user. This method deletes users auth token from Redis Cache.
         * @param {EmptyRequest} body Empty Sign-out request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignoutPost(body: EmptyRequest, options?: any): AxiosPromise<SignoutResponse> {
            return UserApiFp(configuration).authSignoutPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign-up user for the system.
         * @param {SignupEmailRequest} body Sign-up request of the user we want to sign up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignupEmailPost(body: SignupEmailRequest, options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).authSignupEmailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change user password.
         * @param {ChangePasswordRequest} body Change user password.
         * @param {string} changepasswordsource Send &#x27;forgot&#x27; for forgot password, otherwise leave it &amp;lt;empty&amp;gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userChangePasswordPost(body: ChangePasswordRequest, changepasswordsource: string, options?: any): AxiosPromise<ChangePasswordResponse> {
            return UserApiFp(configuration).userChangePasswordPost(body, changepasswordsource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Forgot user password.
         * @param {ForgotPasswordRequest} body Forgot user password.
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userForgotPasswordPost(body: ForgotPasswordRequest, contentLanguage?: string, options?: any): AxiosPromise<ForgotPasswordResponse> {
            return UserApiFp(configuration).userForgotPasswordPost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Match a cane with the logged-in user, drop all link between other users and this cane
         * @param {MatchCaneWithUserRequest} body Match cane with caneId and user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMatchCaneWithUserPost(body: MatchCaneWithUserRequest, options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).userMatchCaneWithUserPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trainee info of a trainer
         * @param {EmptyRequest} body Empty reqeust
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMobilityGetTraineesOfTrainerPost(body: EmptyRequest, options?: any): AxiosPromise<GetUsersResponse> {
            return UserApiFp(configuration).userMobilityGetTraineesOfTrainerPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user's mobility training agreement.
         * @param {MobilityUpdateMobilityTrainingAgreementBody} body Update user&#x27;s mobility training aggreement.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMobilityUpdateMobilityTrainingAgreementPost(body: MobilityUpdateMobilityTrainingAgreementBody, options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).userMobilityUpdateMobilityTrainingAgreementPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user's mobility training information.
         * @param {UpdateUserRequest} body Update user&#x27;s mobility training information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMobilityUpdateMobilityTrainingInformationPost(body: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).userMobilityUpdateMobilityTrainingInformationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user's mobility information.
         * @param {UpdateUserRequest} body Update user&#x27;s mobility information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMobilityUpdatePost(body: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).userMobilityUpdatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user information.
         * @param {UpdateUserRequest} body Update user information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePost(body: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).userUpdatePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {

    /**
     * 
     * @summary Checks if the user with provided email exists.
     * @param {CheckUserEmailRequest} body Checks if the user has already an account on our system-with email provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authCheckUserEmailPost(body: CheckUserEmailRequest, options?: any) {
        return UserApiFp(this.configuration).authCheckUserEmailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Checks if the user's phone number exists.
     * @param {CheckUserPhoneRequest} body Check if the user has already an account on our system-with phone number provided.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authCheckUserPhoneNumberPost(body: CheckUserPhoneRequest, options?: any) {
        return UserApiFp(this.configuration).authCheckUserPhoneNumberPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gathers the information about the authenticated user. Gets the user info from token provided inside the authorization header.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authGetUserInfoGet(options?: any) {
        return UserApiFp(this.configuration).authGetUserInfoGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Sign-in user for the system
     * @param {SigninEmailRequest} body Sign-in request of the user we want to sign in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authSigninEmailPost(body: SigninEmailRequest, options?: any) {
        return UserApiFp(this.configuration).authSigninEmailPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign-in user for the system
     * @param {SigninJustEmailRequest} body Sign-in request of the user we want to sign in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authSigninSendEmailCode(body: SigninJustEmailRequest, options?: any) {
        return UserApiFp(this.configuration).authSigninSendEmailCode(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
    * 
    * @summary Sign-in user for the system
    * @param {SigninJustPhoneRequest} body Sign-in request of the user we want to sign in
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof UserApi
    */
    public authSigninSendSmsCode(body: SigninJustPhoneRequest, options?: any) {
        return UserApiFp(this.configuration).authSigninSendSmsCode(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
    * 
    * @summary Sign-in user for the system
    * @param {SigninJustEmailRequest} body Sign-in request of the user we want to sign in
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof UserApi
    */
    public authSigninValidateEmailCode(body: ValidateEmailCodeRequest, options?: any) {
        return UserApiFp(this.configuration).authSigninValidateEmailCode(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
   * 
   * @summary Sign-in user for the system
   * @param {SigninJustEmailRequest} body Sign-in request of the user we want to sign in
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
    public authSigninValidateSmsCode(body: ValidateSmsCodeRequest, options?: any) {
        return UserApiFp(this.configuration).authSigninValidateSmsCode(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Sign-in user via Firebase Authentication for the system
     * @param {SigninFirebaseRequest} body Sign-in request via **Firebase Authentication** of the user we want to sign in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authSigninFirebasePost(body: SigninFirebaseRequest, options?: any) {
        return UserApiFp(this.configuration).authSigninFirebasePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Sign-out user. This method deletes users auth token from Redis Cache.
     * @param {EmptyRequest} body Empty Sign-out request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authSignoutPost(body: EmptyRequest, options?: any) {
        return UserApiFp(this.configuration).authSignoutPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Sign-up user for the system.
     * @param {SignupEmailRequest} body Sign-up request of the user we want to sign up
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authSignupEmailPost(body: SignupEmailRequest, options?: any) {
        return UserApiFp(this.configuration).authSignupEmailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Change user password.
     * @param {ChangePasswordRequest} body Change user password.
     * @param {string} changepasswordsource Send &#x27;forgot&#x27; for forgot password, otherwise leave it &amp;lt;empty&amp;gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userChangePasswordPost(body: ChangePasswordRequest, changepasswordsource: string, options?: any) {
        return UserApiFp(this.configuration).userChangePasswordPost(body, changepasswordsource, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Forgot user password.
     * @param {ForgotPasswordRequest} body Forgot user password.
     * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, or &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userForgotPasswordPost(body: ForgotPasswordRequest, contentLanguage?: string, options?: any) {
        return UserApiFp(this.configuration).userForgotPasswordPost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Match a cane with the logged-in user, drop all link between other users and this cane
     * @param {MatchCaneWithUserRequest} body Match cane with caneId and user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMatchCaneWithUserPost(body: MatchCaneWithUserRequest, options?: any) {
        return UserApiFp(this.configuration).userMatchCaneWithUserPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get trainee info of a trainer
     * @param {EmptyRequest} body Empty reqeust
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMobilityGetTraineesOfTrainerPost(body: EmptyRequest, options?: any) {
        return UserApiFp(this.configuration).userMobilityGetTraineesOfTrainerPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update user's mobility training agreement.
     * @param {MobilityUpdateMobilityTrainingAgreementBody} body Update user&#x27;s mobility training aggreement.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMobilityUpdateMobilityTrainingAgreementPost(body: MobilityUpdateMobilityTrainingAgreementBody, options?: any) {
        return UserApiFp(this.configuration).userMobilityUpdateMobilityTrainingAgreementPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update user's mobility training information.
     * @param {UpdateUserRequest} body Update user&#x27;s mobility training information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMobilityUpdateMobilityTrainingInformationPost(body: UpdateUserRequest, options?: any) {
        return UserApiFp(this.configuration).userMobilityUpdateMobilityTrainingInformationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update user's mobility information.
     * @param {UpdateUserRequest} body Update user&#x27;s mobility information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userMobilityUpdatePost(body: UpdateUserRequest, options?: any) {
        return UserApiFp(this.configuration).userMobilityUpdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update user information.
     * @param {UpdateUserRequest} body Update user information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdatePost(body: UpdateUserRequest, options?: any) {
        return UserApiFp(this.configuration).userUpdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EmptyRequest } from '../models';
import { ErrorModelResponse } from '../models';
import { GenericBooleanResponse } from '../models';
import { MobilityComparativeStatsResponse } from '../models';
import { MobilityDailyStepsResponse } from '../models';
import { MobilityDetailedStatisticsRequest } from '../models';
import { MobilityDetailedStatisticsResponse } from '../models';
import { MobilityEndLoggingRequest } from '../models';
import { MobilityEndLoggingResponse } from '../models';
import { MobilityGetDaysToBeReviewedRequest } from '../models';
import { MobilityGetDaysToBeReviewedResponse } from '../models';
import { MobilityGetReviewForADayRequest } from '../models';
import { MobilityGetReviewForADayResponse } from '../models';
import { MobilityIngestRequest } from '../models';
import { MobilityIngestResponse } from '../models';
import { MobilityInsertRequest } from '../models';
import { MobilityJourneyRequest } from '../models';
import { MobilityJourneyResponse } from '../models';
import { MobilityJourneysRequest } from '../models';
import { MobilityJourneysResponse } from '../models';
import { MobilityMoreDataRequest } from '../models';
import { MobilityOverviewRequest } from '../models';
import { MobilityOverviewResponse } from '../models';
import { MobilityReviewQuestionsResponse } from '../models';
import { MobilitySendRequest } from '../models';
import { MobilitySendResponse } from '../models';
import { MobilitySendReviewRequest } from '../models';
import { MobilitySendReviewResponseDeprecated } from '../models';
import { MobilitySendUserReviewRequest } from '../models';
import { MobilitySendUserReviewResponse } from '../models';
import { MobilitySnapshotGeneralResponse } from '../models';
import { MobilitySnapshotRequest } from '../models';
import { MobilitySnapshotReviewBasedResponse } from '../models';
import { MobilityStatisticsRequest } from '../models';
import { MobilityStatisticsResponse } from '../models';
import { MobilityStatsResponse } from '../models';
import { MobilitySummaryRequest } from '../models';
import { MobilitySummaryResponse } from '../models';
import { MobilityTrendsRequest } from '../models';
import { MobilityTrendsResponse } from '../models';
/**
 * MobilityApi - axios parameter creator
 * @export
 */
export const MobilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send user mobility data after it ends to backend (Azure Event Hubs)
         * @param {MobilityEndLoggingRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityEndLoggingPost: async (body: MobilityEndLoggingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityEndLoggingPost.');
            }
            const localVarPath = `/mobility/endLogging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users comparative stats
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetCompareStatsPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityGetCompareStatsPost.');
            }
            const localVarPath = `/mobility/getCompareStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user mobility data (daily steps)
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetDailyStepsOfUserPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityGetDailyStepsOfUserPost.');
            }
            const localVarPath = `/mobility/getDailyStepsOfUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user's unreviewed days
         * @param {MobilityGetDaysToBeReviewedRequest} body Request containing userid, start-end date for querying dates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetDaysToBeReviewedPost: async (body: MobilityGetDaysToBeReviewedRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityGetDaysToBeReviewedPost.');
            }
            const localVarPath = `/mobility/getDaysToBeReviewed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user mobility data (step stats)
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetMobilityStatsPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityGetMobilityStatsPost.');
            }
            const localVarPath = `/mobility/getMobilityStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users full mobility data steps/distance
         * @param {MobilityMoreDataRequest} body Request containing extra info type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetMoreMobilityStatsPost: async (body: MobilityMoreDataRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityGetMoreMobilityStatsPost.');
            }
            const localVarPath = `/mobility/getMoreMobilityStats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user's reviewe for the day
         * @param {MobilityGetReviewForADayRequest} body Request containing userid and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetReviewForADayPost: async (body: MobilityGetReviewForADayRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityGetReviewForADayPost.');
            }
            const localVarPath = `/mobility/getReviewForADay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user mobility data to backend.
         * @param {MobilityIngestRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityIngestPost: async (body: MobilityIngestRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityIngestPost.');
            }
            const localVarPath = `/mobility/ingest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user mobility data to backend.
         * @param {MobilityInsertRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityInsertPost: async (body: MobilityInsertRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityInsertPost.');
            }
            const localVarPath = `/mobility/insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show journey detail of user (session info)
         * @param {MobilityJourneyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityJourneyPost: async (body: MobilityJourneyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityJourneyPost.');
            }
            const localVarPath = `/mobility/journey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show journeys of user
         * @param {MobilityJourneysRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityJourneysPost: async (body: MobilityJourneysRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityJourneysPost.');
            }
            const localVarPath = `/mobility/journeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show overview for mobility for chosen user
         * @param {MobilityOverviewRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityOverviewPost: async (body: MobilityOverviewRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityOverviewPost.');
            }
            const localVarPath = `/mobility/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get mobility review questions
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityQuestionsPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityQuestionsPost.');
            }
            const localVarPath = `/mobility/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user mobility data after it ends to backend (Azure Event Hubs)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityReceivePost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mobility/receive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user mobility data to backend (Azure Event Hubs)
         * @param {MobilitySendRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySendPost: async (body: MobilitySendRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilitySendPost.');
            }
            const localVarPath = `/mobility/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user's review
         * @param {MobilitySendReviewRequest} body Request containing confidence level and other answers of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySendReviewDeprecatedPost: async (body: MobilitySendReviewRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilitySendReviewDeprecatedPost.');
            }
            const localVarPath = `/mobility/sendReviewDeprecated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send user's review
         * @param {MobilitySendUserReviewRequest} body Request containing confidence level and other answers of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySendUserReviewPost: async (body: MobilitySendUserReviewRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilitySendUserReviewPost.');
            }
            const localVarPath = `/mobility/sendUserReview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get mobility snapshot for user
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySnapshotGeneralPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilitySnapshotGeneralPost.');
            }
            const localVarPath = `/mobility/snapshot/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get mobility snapshot for user
         * @param {MobilitySnapshotRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySnapshotReviewBasedPost: async (body: MobilitySnapshotRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilitySnapshotReviewBasedPost.');
            }
            const localVarPath = `/mobility/snapshot/reviewBased`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user mobility data details according to the page the user is viewing
         * @param {MobilityDetailedStatisticsRequest} body Request contains mobility stat dataType and dateType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityStatisticsGetDetailedPost: async (body: MobilityDetailedStatisticsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityStatisticsGetDetailedPost.');
            }
            const localVarPath = `/mobility/statistics/getDetailed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user mobility data according to the page the user is viewing
         * @param {MobilityStatisticsRequest} body Request contains mobility stat dataType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityStatisticsGetPost: async (body: MobilityStatisticsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityStatisticsGetPost.');
            }
            const localVarPath = `/mobility/statistics/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show summary for mobility for chosen user
         * @param {MobilitySummaryRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySummaryPost: async (body: MobilitySummaryRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilitySummaryPost.');
            }
            const localVarPath = `/mobility/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get mobility trends data
         * @param {MobilityTrendsRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityTrendsPost: async (body: MobilityTrendsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mobilityTrendsPost.');
            }
            const localVarPath = `/mobility/trends`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobilityApi - functional programming interface
 * @export
 */
export const MobilityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send user mobility data after it ends to backend (Azure Event Hubs)
         * @param {MobilityEndLoggingRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityEndLoggingPost(body: MobilityEndLoggingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityEndLoggingResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityEndLoggingPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get users comparative stats
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityGetCompareStatsPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityComparativeStatsResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityGetCompareStatsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user mobility data (daily steps)
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityGetDailyStepsOfUserPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityDailyStepsResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityGetDailyStepsOfUserPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user's unreviewed days
         * @param {MobilityGetDaysToBeReviewedRequest} body Request containing userid, start-end date for querying dates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityGetDaysToBeReviewedPost(body: MobilityGetDaysToBeReviewedRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityGetDaysToBeReviewedResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityGetDaysToBeReviewedPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user mobility data (step stats)
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityGetMobilityStatsPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityStatsResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityGetMobilityStatsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get users full mobility data steps/distance
         * @param {MobilityMoreDataRequest} body Request containing extra info type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityGetMoreMobilityStatsPost(body: MobilityMoreDataRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityStatsResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityGetMoreMobilityStatsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user's reviewe for the day
         * @param {MobilityGetReviewForADayRequest} body Request containing userid and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityGetReviewForADayPost(body: MobilityGetReviewForADayRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityGetReviewForADayResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityGetReviewForADayPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send user mobility data to backend.
         * @param {MobilityIngestRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityIngestPost(body: MobilityIngestRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityIngestResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityIngestPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send user mobility data to backend.
         * @param {MobilityInsertRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityInsertPost(body: MobilityInsertRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericBooleanResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityInsertPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Show journey detail of user (session info)
         * @param {MobilityJourneyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityJourneyPost(body: MobilityJourneyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityJourneyResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityJourneyPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Show journeys of user
         * @param {MobilityJourneysRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityJourneysPost(body: MobilityJourneysRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityJourneysResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityJourneysPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Show overview for mobility for chosen user
         * @param {MobilityOverviewRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityOverviewPost(body: MobilityOverviewRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityOverviewResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityOverviewPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get mobility review questions
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityQuestionsPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityReviewQuestionsResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityQuestionsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send user mobility data after it ends to backend (Azure Event Hubs)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityReceivePost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityEndLoggingResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityReceivePost(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send user mobility data to backend (Azure Event Hubs)
         * @param {MobilitySendRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilitySendPost(body: MobilitySendRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilitySendResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilitySendPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send user's review
         * @param {MobilitySendReviewRequest} body Request containing confidence level and other answers of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilitySendReviewDeprecatedPost(body: MobilitySendReviewRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilitySendReviewResponseDeprecated>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilitySendReviewDeprecatedPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send user's review
         * @param {MobilitySendUserReviewRequest} body Request containing confidence level and other answers of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilitySendUserReviewPost(body: MobilitySendUserReviewRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilitySendUserReviewResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilitySendUserReviewPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get mobility snapshot for user
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilitySnapshotGeneralPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilitySnapshotGeneralResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilitySnapshotGeneralPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get mobility snapshot for user
         * @param {MobilitySnapshotRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilitySnapshotReviewBasedPost(body: MobilitySnapshotRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilitySnapshotReviewBasedResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilitySnapshotReviewBasedPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user mobility data details according to the page the user is viewing
         * @param {MobilityDetailedStatisticsRequest} body Request contains mobility stat dataType and dateType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityStatisticsGetDetailedPost(body: MobilityDetailedStatisticsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityDetailedStatisticsResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityStatisticsGetDetailedPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user mobility data according to the page the user is viewing
         * @param {MobilityStatisticsRequest} body Request contains mobility stat dataType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityStatisticsGetPost(body: MobilityStatisticsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityStatisticsResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityStatisticsGetPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Show summary for mobility for chosen user
         * @param {MobilitySummaryRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilitySummaryPost(body: MobilitySummaryRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilitySummaryResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilitySummaryPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get mobility trends data
         * @param {MobilityTrendsRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilityTrendsPost(body: MobilityTrendsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobilityTrendsResponse>> {
            const localVarAxiosArgs = await MobilityApiAxiosParamCreator(configuration).mobilityTrendsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MobilityApi - factory interface
 * @export
 */
export const MobilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Send user mobility data after it ends to backend (Azure Event Hubs)
         * @param {MobilityEndLoggingRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityEndLoggingPost(body: MobilityEndLoggingRequest, options?: any): AxiosPromise<MobilityEndLoggingResponse> {
            return MobilityApiFp(configuration).mobilityEndLoggingPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users comparative stats
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetCompareStatsPost(body: EmptyRequest, options?: any): AxiosPromise<MobilityComparativeStatsResponse> {
            return MobilityApiFp(configuration).mobilityGetCompareStatsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user mobility data (daily steps)
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetDailyStepsOfUserPost(body: EmptyRequest, options?: any): AxiosPromise<MobilityDailyStepsResponse> {
            return MobilityApiFp(configuration).mobilityGetDailyStepsOfUserPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user's unreviewed days
         * @param {MobilityGetDaysToBeReviewedRequest} body Request containing userid, start-end date for querying dates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetDaysToBeReviewedPost(body: MobilityGetDaysToBeReviewedRequest, options?: any): AxiosPromise<MobilityGetDaysToBeReviewedResponse> {
            return MobilityApiFp(configuration).mobilityGetDaysToBeReviewedPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user mobility data (step stats)
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetMobilityStatsPost(body: EmptyRequest, options?: any): AxiosPromise<MobilityStatsResponse> {
            return MobilityApiFp(configuration).mobilityGetMobilityStatsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users full mobility data steps/distance
         * @param {MobilityMoreDataRequest} body Request containing extra info type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetMoreMobilityStatsPost(body: MobilityMoreDataRequest, options?: any): AxiosPromise<MobilityStatsResponse> {
            return MobilityApiFp(configuration).mobilityGetMoreMobilityStatsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user's reviewe for the day
         * @param {MobilityGetReviewForADayRequest} body Request containing userid and date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityGetReviewForADayPost(body: MobilityGetReviewForADayRequest, options?: any): AxiosPromise<MobilityGetReviewForADayResponse> {
            return MobilityApiFp(configuration).mobilityGetReviewForADayPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user mobility data to backend.
         * @param {MobilityIngestRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityIngestPost(body: MobilityIngestRequest, options?: any): AxiosPromise<MobilityIngestResponse> {
            return MobilityApiFp(configuration).mobilityIngestPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user mobility data to backend.
         * @param {MobilityInsertRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityInsertPost(body: MobilityInsertRequest, options?: any): AxiosPromise<GenericBooleanResponse> {
            return MobilityApiFp(configuration).mobilityInsertPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show journey detail of user (session info)
         * @param {MobilityJourneyRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityJourneyPost(body: MobilityJourneyRequest, options?: any): AxiosPromise<MobilityJourneyResponse> {
            return MobilityApiFp(configuration).mobilityJourneyPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show journeys of user
         * @param {MobilityJourneysRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityJourneysPost(body: MobilityJourneysRequest, options?: any): AxiosPromise<MobilityJourneysResponse> {
            return MobilityApiFp(configuration).mobilityJourneysPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show overview for mobility for chosen user
         * @param {MobilityOverviewRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityOverviewPost(body: MobilityOverviewRequest, options?: any): AxiosPromise<MobilityOverviewResponse> {
            return MobilityApiFp(configuration).mobilityOverviewPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get mobility review questions
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityQuestionsPost(body: EmptyRequest, options?: any): AxiosPromise<MobilityReviewQuestionsResponse> {
            return MobilityApiFp(configuration).mobilityQuestionsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user mobility data after it ends to backend (Azure Event Hubs)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityReceivePost(options?: any): AxiosPromise<MobilityEndLoggingResponse> {
            return MobilityApiFp(configuration).mobilityReceivePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user mobility data to backend (Azure Event Hubs)
         * @param {MobilitySendRequest} body Request containing user app / cane data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySendPost(body: MobilitySendRequest, options?: any): AxiosPromise<MobilitySendResponse> {
            return MobilityApiFp(configuration).mobilitySendPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user's review
         * @param {MobilitySendReviewRequest} body Request containing confidence level and other answers of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySendReviewDeprecatedPost(body: MobilitySendReviewRequest, options?: any): AxiosPromise<MobilitySendReviewResponseDeprecated> {
            return MobilityApiFp(configuration).mobilitySendReviewDeprecatedPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send user's review
         * @param {MobilitySendUserReviewRequest} body Request containing confidence level and other answers of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySendUserReviewPost(body: MobilitySendUserReviewRequest, options?: any): AxiosPromise<MobilitySendUserReviewResponse> {
            return MobilityApiFp(configuration).mobilitySendUserReviewPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get mobility snapshot for user
         * @param {EmptyRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySnapshotGeneralPost(body: EmptyRequest, options?: any): AxiosPromise<MobilitySnapshotGeneralResponse> {
            return MobilityApiFp(configuration).mobilitySnapshotGeneralPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get mobility snapshot for user
         * @param {MobilitySnapshotRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySnapshotReviewBasedPost(body: MobilitySnapshotRequest, options?: any): AxiosPromise<MobilitySnapshotReviewBasedResponse> {
            return MobilityApiFp(configuration).mobilitySnapshotReviewBasedPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user mobility data details according to the page the user is viewing
         * @param {MobilityDetailedStatisticsRequest} body Request contains mobility stat dataType and dateType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityStatisticsGetDetailedPost(body: MobilityDetailedStatisticsRequest, options?: any): AxiosPromise<MobilityDetailedStatisticsResponse> {
            return MobilityApiFp(configuration).mobilityStatisticsGetDetailedPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user mobility data according to the page the user is viewing
         * @param {MobilityStatisticsRequest} body Request contains mobility stat dataType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityStatisticsGetPost(body: MobilityStatisticsRequest, options?: any): AxiosPromise<MobilityStatisticsResponse> {
            return MobilityApiFp(configuration).mobilityStatisticsGetPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show summary for mobility for chosen user
         * @param {MobilitySummaryRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilitySummaryPost(body: MobilitySummaryRequest, options?: any): AxiosPromise<MobilitySummaryResponse> {
            return MobilityApiFp(configuration).mobilitySummaryPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get mobility trends data
         * @param {MobilityTrendsRequest} body Empty request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilityTrendsPost(body: MobilityTrendsRequest, options?: any): AxiosPromise<MobilityTrendsResponse> {
            return MobilityApiFp(configuration).mobilityTrendsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobilityApi - object-oriented interface
 * @export
 * @class MobilityApi
 * @extends {BaseAPI}
 */
export class MobilityApi extends BaseAPI {
    /**
     * 
     * @summary Send user mobility data after it ends to backend (Azure Event Hubs)
     * @param {MobilityEndLoggingRequest} body Request containing user app / cane data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityEndLoggingPost(body: MobilityEndLoggingRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityEndLoggingPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get users comparative stats
     * @param {EmptyRequest} body Empty request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityGetCompareStatsPost(body: EmptyRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityGetCompareStatsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user mobility data (daily steps)
     * @param {EmptyRequest} body Empty request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityGetDailyStepsOfUserPost(body: EmptyRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityGetDailyStepsOfUserPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user's unreviewed days
     * @param {MobilityGetDaysToBeReviewedRequest} body Request containing userid, start-end date for querying dates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityGetDaysToBeReviewedPost(body: MobilityGetDaysToBeReviewedRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityGetDaysToBeReviewedPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user mobility data (step stats)
     * @param {EmptyRequest} body Empty request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityGetMobilityStatsPost(body: EmptyRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityGetMobilityStatsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get users full mobility data steps/distance
     * @param {MobilityMoreDataRequest} body Request containing extra info type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityGetMoreMobilityStatsPost(body: MobilityMoreDataRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityGetMoreMobilityStatsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user's reviewe for the day
     * @param {MobilityGetReviewForADayRequest} body Request containing userid and date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityGetReviewForADayPost(body: MobilityGetReviewForADayRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityGetReviewForADayPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send user mobility data to backend.
     * @param {MobilityIngestRequest} body Request containing user app / cane data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityIngestPost(body: MobilityIngestRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityIngestPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send user mobility data to backend.
     * @param {MobilityInsertRequest} body Request containing user app / cane data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityInsertPost(body: MobilityInsertRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityInsertPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Show journey detail of user (session info)
     * @param {MobilityJourneyRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityJourneyPost(body: MobilityJourneyRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityJourneyPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Show journeys of user
     * @param {MobilityJourneysRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityJourneysPost(body: MobilityJourneysRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityJourneysPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Show overview for mobility for chosen user
     * @param {MobilityOverviewRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityOverviewPost(body: MobilityOverviewRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityOverviewPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get mobility review questions
     * @param {EmptyRequest} body Empty request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityQuestionsPost(body: EmptyRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityQuestionsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send user mobility data after it ends to backend (Azure Event Hubs)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityReceivePost(options?: any) {
        return MobilityApiFp(this.configuration).mobilityReceivePost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send user mobility data to backend (Azure Event Hubs)
     * @param {MobilitySendRequest} body Request containing user app / cane data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilitySendPost(body: MobilitySendRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilitySendPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send user's review
     * @param {MobilitySendReviewRequest} body Request containing confidence level and other answers of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilitySendReviewDeprecatedPost(body: MobilitySendReviewRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilitySendReviewDeprecatedPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send user's review
     * @param {MobilitySendUserReviewRequest} body Request containing confidence level and other answers of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilitySendUserReviewPost(body: MobilitySendUserReviewRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilitySendUserReviewPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get mobility snapshot for user
     * @param {EmptyRequest} body Empty request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilitySnapshotGeneralPost(body: EmptyRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilitySnapshotGeneralPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get mobility snapshot for user
     * @param {MobilitySnapshotRequest} body Empty request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilitySnapshotReviewBasedPost(body: MobilitySnapshotRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilitySnapshotReviewBasedPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user mobility data details according to the page the user is viewing
     * @param {MobilityDetailedStatisticsRequest} body Request contains mobility stat dataType and dateType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityStatisticsGetDetailedPost(body: MobilityDetailedStatisticsRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityStatisticsGetDetailedPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user mobility data according to the page the user is viewing
     * @param {MobilityStatisticsRequest} body Request contains mobility stat dataType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityStatisticsGetPost(body: MobilityStatisticsRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityStatisticsGetPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Show summary for mobility for chosen user
     * @param {MobilitySummaryRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilitySummaryPost(body: MobilitySummaryRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilitySummaryPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get mobility trends data
     * @param {MobilityTrendsRequest} body Empty request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobilityApi
     */
    public mobilityTrendsPost(body: MobilityTrendsRequest, options?: any) {
        return MobilityApiFp(this.configuration).mobilityTrendsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}

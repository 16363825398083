/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DeleteLocationRequest } from '../models';
import { DeleteLocationResponse } from '../models';
import { EmptyRequest } from '../models';
import { ErrorModelResponse } from '../models';
import { GetLocationRequest } from '../models';
import { GetLocationResponse } from '../models';
import { GetLocationsResponse } from '../models';
import { SaveLocationRequest } from '../models';
import { SaveLocationResponse } from '../models';
import { UpdateLocationRequest } from '../models';
import { UpdateLocationResponse } from '../models';
/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete user location.
         * @param {DeleteLocationRequest} body Delete user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsDeletePost: async (body: DeleteLocationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userLocationsDeletePost.');
            }
            const localVarPath = `/user/locations/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get specific user location.
         * @param {GetLocationRequest} body Get user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsLocationPost: async (body: GetLocationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userLocationsLocationPost.');
            }
            const localVarPath = `/user/locations/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get locations of a user.
         * @param {EmptyRequest} body Empty locations request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsPost: async (body: EmptyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userLocationsPost.');
            }
            const localVarPath = `/user/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save user location.
         * @param {SaveLocationRequest} body Save user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsSavePost: async (body: SaveLocationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userLocationsSavePost.');
            }
            const localVarPath = `/user/locations/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user location.
         * @param {UpdateLocationRequest} body Update user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsUpdatePost: async (body: UpdateLocationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userLocationsUpdatePost.');
            }
            const localVarPath = `/user/locations/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete user location.
         * @param {DeleteLocationRequest} body Delete user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLocationsDeletePost(body: DeleteLocationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteLocationResponse>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).userLocationsDeletePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get specific user location.
         * @param {GetLocationRequest} body Get user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLocationsLocationPost(body: GetLocationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLocationResponse>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).userLocationsLocationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get locations of a user.
         * @param {EmptyRequest} body Empty locations request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLocationsPost(body: EmptyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLocationsResponse>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).userLocationsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save user location.
         * @param {SaveLocationRequest} body Save user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLocationsSavePost(body: SaveLocationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveLocationResponse>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).userLocationsSavePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user location.
         * @param {UpdateLocationRequest} body Update user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLocationsUpdatePost(body: UpdateLocationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateLocationResponse>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).userLocationsUpdatePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete user location.
         * @param {DeleteLocationRequest} body Delete user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsDeletePost(body: DeleteLocationRequest, options?: any): AxiosPromise<DeleteLocationResponse> {
            return LocationApiFp(configuration).userLocationsDeletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get specific user location.
         * @param {GetLocationRequest} body Get user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsLocationPost(body: GetLocationRequest, options?: any): AxiosPromise<GetLocationResponse> {
            return LocationApiFp(configuration).userLocationsLocationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get locations of a user.
         * @param {EmptyRequest} body Empty locations request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsPost(body: EmptyRequest, options?: any): AxiosPromise<GetLocationsResponse> {
            return LocationApiFp(configuration).userLocationsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save user location.
         * @param {SaveLocationRequest} body Save user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsSavePost(body: SaveLocationRequest, options?: any): AxiosPromise<SaveLocationResponse> {
            return LocationApiFp(configuration).userLocationsSavePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user location.
         * @param {UpdateLocationRequest} body Update user location information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLocationsUpdatePost(body: UpdateLocationRequest, options?: any): AxiosPromise<UpdateLocationResponse> {
            return LocationApiFp(configuration).userLocationsUpdatePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @summary Delete user location.
     * @param {DeleteLocationRequest} body Delete user location information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public userLocationsDeletePost(body: DeleteLocationRequest, options?: any) {
        return LocationApiFp(this.configuration).userLocationsDeletePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get specific user location.
     * @param {GetLocationRequest} body Get user location information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public userLocationsLocationPost(body: GetLocationRequest, options?: any) {
        return LocationApiFp(this.configuration).userLocationsLocationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get locations of a user.
     * @param {EmptyRequest} body Empty locations request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public userLocationsPost(body: EmptyRequest, options?: any) {
        return LocationApiFp(this.configuration).userLocationsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Save user location.
     * @param {SaveLocationRequest} body Save user location information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public userLocationsSavePost(body: SaveLocationRequest, options?: any) {
        return LocationApiFp(this.configuration).userLocationsSavePost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update user location.
     * @param {UpdateLocationRequest} body Update user location information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public userLocationsUpdatePost(body: UpdateLocationRequest, options?: any) {
        return LocationApiFp(this.configuration).userLocationsUpdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}

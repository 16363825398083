

























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { UserModel } from "@/wwapi/models";
import { GetUsersResponse } from "@/wwapi/models/get-users-response";
import { getToken } from "@/utils/cookies";
import { defaultUserData } from "../../defaultModels/IUserData";
import { Form } from "element-ui";

const boolTypeOptions = [
  { key: "true", displayName: "true" },
  { key: "false", displayName: "false" },
  { key: "", displayName: "leave empty" },
];

const loginTypeOptions = [
  { key: "apple", displayName: "Apple" },
  { key: "google", displayName: "Google" },
  { key: "facebook", displayName: "Facebook" },
  { key: "email", displayName: "Email" },
  { key: "anonymous", displayName: "Anonymous" },
  { key: "", displayName: "Leave Empty" },
];
@Component({
  name: "Users",
})
export default class extends Vue {
  private list: UserModel[] = [];
  private applicationRegions: string[] = [];
  private listLoading = true;
  private boolTypeOptions = boolTypeOptions;
  private loginTypeOptions = loginTypeOptions;
  private tempUserData = defaultUserData;
  private dialogFormVisible = false;
  private dialogStatus = "";
  private textMap = {
    update: "Edit",
    create: "Create",
  };

  private listQuery = {
    isPremium: null,
    isBetaUser: null,
    isAssistant: null,
    loginType: null,
    text: null,
    sort: "+id",
  };

  created() {
    this.fetchData();
  }

  private async fetchData() {
    try {
      this.listLoading = true;
      const adminApi = new AdminApi();
      if (this.listQuery.isPremium === "") {
        this.listQuery.isPremium = null;
      }

      if (this.listQuery.isBetaUser === "") {
        this.listQuery.isBetaUser = null;
      }

      if (this.listQuery.isAssistant === "") {
        this.listQuery.isAssistant = null;
      }

      if (this.listQuery.loginType === "") {
        this.listQuery.loginType = null;
      }
      console.dir(this.listQuery);
      const response = await adminApi.userListPost(this.listQuery);
      const applicationRegionResponse = await adminApi.getClientSettings();
      const regions = applicationRegionResponse.data.data.find(
        (x) => x.key === "CONSULT_REGIONS"
      );
      this.applicationRegions = regions!.value.split(";");
      const responseModel: GetUsersResponse = response.data;
      const users: UserModel[] = responseModel.data;
      if (users.length && users.length > 0) {
        users.map((user) => {
          if (user.consultSeconds) {
            user.consultSeconds = user.consultSeconds / 60;
          }
        });
        this.list = users;
      } else this.list = [];
      console.log("data returned from getusers" + JSON.stringify(users));

      // this.list = response.data.items;
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false;
      }, 0.5 * 1000);
    } catch (error) {
      alert(error);
    }
  }

  private handleFilter() {
    this.fetchData();
  }

  private async handleToggleBeta(row: any, index: number) {
    try {
      const adminApi = new AdminApi();
      const options = {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };
      this.listLoading = true;
      const response = await adminApi.userToggleBetaUserPost(
        {
          userId: index,
        },
        options
      );
      console.log("res.status: " + response.status);
      if (response.status !== 200) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "An error occured. Message: " + response.data.status.message,
          type: "error",
          duration: 2000,
        });
        this.listLoading = false;
      } else {
        this.list = this.list.map((l) => {
          if (l.userId === index) {
            l.isBetaUser = response.data.data.isBetaUser;
          }
          return l;
        });
        this.listLoading = false;

        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message:
            "Beta user status changed to <b>" +
            response.data.data.isBetaUser +
            "</b> for user <b>" +
            row.email +
            "</b>",
          type: "success",
          duration: 2000,
        });
      }
    } catch (err) {
      this.$notify({
        title: "Error",
        dangerouslyUseHTMLString: true,
        message: "An error occured. Message: " + err.message,
        type: "error",
        duration: 2000,
      });
      this.listLoading = false;
    }
  }

  private async handleToggleCanUseConsult(row: any, index: number) {
    try {
      const adminApi = new AdminApi();
      const options = {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };
      this.listLoading = true;
      const response = await adminApi.userToggleCanUseConsult(
        {
          userId: index,
        },
        options
      );
      console.log("res.status: " + response.status);
      if (response.status !== 200) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "An error occured. Message: " + response.data.status.message,
          type: "error",
          duration: 2000,
        });
        this.listLoading = false;
      } else {
        this.list = this.list.map((l) => {
          if (l.userId === index) {
            l.canUseConsult = response.data.data.canUseConsult;
          }
          return l;
        });
        this.listLoading = false;

        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message:
            "User use consult value changed to <b>" +
            response.data.data.canUseConsult +
            "</b> for user <b>" +
            row.email +
            "</b>",
          type: "success",
          duration: 2000,
        });
      }
    } catch (err) {
      this.$notify({
        title: "Error",
        dangerouslyUseHTMLString: true,
        message: "An error occured. Message: " + err.message,
        type: "error",
        duration: 2000,
      });
      this.listLoading = false;
    }
  }

  private handleUpdate(row: any) {
    console.log("keyis" + JSON.stringify(row.productIds));
    if (row.client === "1") {
      console.log("ios");
    } else {
      console.log("android");
    }
    this.tempUserData = Object.assign({}, row);
    this.tempUserData.consultSeconds = this.tempUserData.consultSeconds;
    this.dialogStatus = "update";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private updateData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempUserData);
        const { data } = await adminApi.userUpdateUserFromAdminPost({
          user: {
            userId: tempData.userId,
            firstName: tempData.firstName,
            lastName: tempData.lastName,
            phoneNumber: tempData.phoneNumber,
            countryCode: tempData.countryCode,
            country: tempData.country,
            city: tempData.city,
            userType: tempData.userType,
            caneId: tempData.caneId,
            email: tempData.email,
            isAssistant: tempData.isAssistant,
            consultSeconds: tempData.consultSeconds * 60,
            assistantRegion: tempData.assistantRegion,
          },
        });
        const index = this.list.findIndex(
          (v: any) => v.userId === data.data.userId
        );
        data.data.consultSeconds = data.data.consultSeconds / 60;
        this.list.splice(index, 1, data.data);
        this.dialogFormVisible = false;
        this.$notify({
          title: "Success",
          message: "Successfully update the user",
          type: "success",
          duration: 2000,
        });
      }
    });
  }
}






























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { cloneDeep } from "lodash";
import { Form } from "element-ui";
import { GetCompaniesResponse } from "@/wwapi/models/get-companies-response";
import { defaultCompanyData } from "../../defaultModels/ICompanyData";

@Component({
  name: "CompanyList",
})
export default class extends Vue {
  private list: GetCompaniesResponse[] = [];
  private showFullDescription: Array<any> = [];
  private listLoading = true;
  private dialogFormVisible = false;
  private deleteConfirmationVisible = false;
  private deleteItemId = null;
  private dialogStatus = "";
  private tempCompanyData = defaultCompanyData;
  private textMap = {
    update: "Edit",
    create: "Create",
  };

  created() {
    this.fetchData();
  }

  shortenDescription(description: string) {
    if (description.length > 20) {
      return description.slice(0, 20) + "...";
    }
    return description;
  }

  toggleDescription(index: number) {
    this.$set(
      this.showFullDescription,
      index,
      !this.showFullDescription[index]
    );
  }

  private async fetchData() {
    this.listLoading = true;
    const adminApi = new AdminApi();
    const response = await adminApi.getCompanies({
      includeIsActiveCondition: false,
    });
    console.log(response);
    if (response.data.data.length) this.list = response.data.data;
    else this.list = [];
    console.log(
      "data returned from getCompanies" + JSON.stringify(response.data.data)
    );
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  showDeleteConfirmation(row: any) {
    this.deleteItemId = row.id;
    this.deleteConfirmationVisible = true;
  }

  handleDeleteConfirmationClose(done: any) {
    if (done) {
      this.deleteItemId = null;
      this.deleteConfirmationVisible = false;
    }
  }

  async deleteItem() {
    console.log(`Deleting item with ID: ${this.deleteItemId}`);
    const adminApi = new AdminApi();
    const { data } = await adminApi.deleteCompany({
      freezoneSponsorshipId: this.deleteItemId,
    });
    if (data.status.code === "success") {
      const index = this.list.findIndex((v: any) => v.id === this.deleteItemId);
      this.list.splice(index, 1);
      this.$notify({
        title: "Success",
        message: "Successfully company deleted",
        type: "success",
        duration: 2000,
      });
    }
    this.deleteItemId = null;
    this.deleteConfirmationVisible = false;
  }

  private handleUpdate(row: any) {
    this.tempCompanyData = Object.assign({}, row);
    this.dialogStatus = "update";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private updateData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempCompanyData);
        const { data } = await adminApi.updateCompany({
          id: tempData.id,
          name: tempData.name,
          description: tempData.description,
          header: tempData.header,
          url: tempData.url,
          orderNo: tempData.orderNo,
          isActive: tempData.isActive,
        });
        const index = this.list.findIndex((v: any) => v.id === tempData.id);
        if (data.status.code === "success") {
          if (tempData.isActive === false) {
            this.list.splice(index, 1);
          } else {
            this.list.splice(index, 1, tempData);
          }
          this.dialogFormVisible = false;
          this.$notify({
            title: "Success",
            message: "Successfully update the company",
            type: "success",
            duration: 2000,
          });
        }
      }
    });
  }

  private handleCreate() {
    this.tempCompanyData = cloneDeep(defaultCompanyData);
    this.dialogStatus = "create";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private createData() {
    const adminApi = new AdminApi();

    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempCompanyData);
        const { data } = await adminApi.postCompany({
          name: tempData.name,
          description: tempData.description,
          header: tempData.header,
          url: tempData.url,
          orderNo: tempData.orderNo,
          isActive: tempData.isActive,
        });
        if (data && data.data) {
          this.list = [...this.list, data.data];
        }
        this.dialogFormVisible = false;
        this.$notify({
          title: "Success",
          message: "Successfully added new company",
          type: "success",
          duration: 2000,
        });
      }
    });
  }

  private async toggleCompanyActive(row: any, isActive: boolean) {
    const adminApi = new AdminApi();
    const { data } = await adminApi.updateCompany({
      id: row.id,
      name: row.name,
      description: row.description,
      header: row.header,
      url: row.url,
      orderNo: row.orderNo,
      isActive: isActive,
    });
    if (data.status.code === "success") {
      if (!isActive) {
        const index = this.list.findIndex((v: any) => v.id === data.data.id);
        this.list.splice(index, 1);
      }
      this.$notify({
        title: "Success",
        message: "Successfully company status changed",
        type: "success",
        duration: 2000,
      });
    }
  }
}

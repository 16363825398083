






























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'CommentDropdown'
})
export default class extends Vue {
  @Prop({ required: true }) private value!: boolean

  get disableComment() {
    return this.value
  }

  set disableComment(value) {
    this.$emit('input', value)
  }
}


































































































































































































import { Component, Vue } from "vue-property-decorator";
import Vue2Filters from "vue2-filters";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { CouponCodesAndProductsModel } from "@/wwapi/models/coupon-codes-and-products-model";
import { CouponCodesAndProductsResponse } from "@/wwapi/models/coupon-codes-and-products-response";
import { cloneDeep } from "lodash";
import { Form } from "element-ui";
import { defaultCouponCodeData } from "../../defaultModels/ICouponCodeData";

Vue.use(Vue2Filters);

const clientTypeOptions = [
  { key: "0", displayName: "Web" },
  { key: "1", displayName: "iOS" },
  { key: "2", displayName: "Android" },
];
const clientTypeKeyValue = clientTypeOptions.reduce(
  (acc: { [key: string]: string }, cur) => {
    acc[cur.key] = cur.displayName;
    return acc;
  },
  {}
) as { [key: string]: string };
@Component({
  name: "CouponCodes",
  filters: {
    clientTypeFilter: (type: string) => {
      return clientTypeKeyValue[type];
    },
  },
  mixins: [Vue2Filters.mixin],
})
export default class extends Vue {
  private list: CouponCodesAndProductsModel[] = [];
  private listLoading = true;
  private dialogFormVisible = false;
  private dialogStatus = "";
  private textMap = {
    update: "Edit",
    create: "Create",
  };

  private rules = {
    type: [{ required: true, message: "type is required", trigger: "change" }],
    timestamp: [
      { required: true, message: "timestamp is required", trigger: "change" },
    ],
    title: [{ required: true, message: "title is required", trigger: "blur" }],
  };

  private tempCouponCodeData = defaultCouponCodeData;
  private clientTypeOptions = clientTypeOptions;
  // private boolTypeOptions = boolTypeOptions;

  // private listQuery = {
  //   isPremium: null,
  //   isBetaUser: null,
  //   text: null,
  //   sort: "+id",
  // };
  created() {
    this.fetchData();
  }

  private async fetchData() {
    this.listLoading = true;
    const adminApi = new AdminApi();
    const response = await adminApi.subscriptionGetCouponCodesAndProductsGet();
    const responseModel: CouponCodesAndProductsResponse = response.data;
    const users: CouponCodesAndProductsModel[] = responseModel.data;
    if (users.length && users.length > 0) this.list = users;
    else this.list = [];
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  private resetTempArticleData() {
    this.tempCouponCodeData = cloneDeep(defaultCouponCodeData);
  }

  private handleCreate() {
    this.resetTempArticleData();
    this.dialogStatus = "create";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private createData() {
    const adminApi = new AdminApi();

    (this.$refs.dataForm as Form).validate(async (valid) => {
      // if (valid) {
      const couponCodeData = this.tempCouponCodeData;
      // couponCodeData.id = Math.round(Math.random() * 100) + 1024; // mock a id
      // couponCodeData.author = "vue-typescript-admin";
      const { data } = await adminApi.subscriptionAddCouponCodePost({
        couponCode: couponCodeData.couponCode,
        productIdForAndroid: couponCodeData.productIdForAndroid,
        basePlanIdForAndroid: couponCodeData.basePlanIdForAndroid,
        offerIdForAndroid: couponCodeData.offerIdForAndroid,
        productIdForIOS: couponCodeData.productIdForIOS,
        offerIdForIOS: couponCodeData.offerIdForIOS,
        limit: couponCodeData.limit,
        expireDate: couponCodeData.expireDate,
      });
      console.log("ccd.client111: " + JSON.stringify(data));
      if (data && data.data) {
        console.log("111 " + JSON.stringify(data));
        data.data.map((newElement: any) => this.list.unshift(newElement));
      }
      this.dialogFormVisible = false;
      this.$notify({
        title: "Success",
        message: "Successfully added new coupon code",
        type: "success",
        duration: 2000,
      });
      // }
    });
  }

  private handleUpdate(row: any) {
    this.$set(this, "tempCouponCodeData", { ...row });

    if (row.client === 1) {
      this.$set(this.tempCouponCodeData, "productIdForIOS", row.productId);
      this.$set(this.tempCouponCodeData, "offerIdForIOS", row.offerId);
    } else {
      this.$set(this.tempCouponCodeData, "productIdForAndroid", row.productId);
      this.$set(
        this.tempCouponCodeData,
        "basePlanIdForAndroid",
        row.basePlanId
      );
      this.$set(this.tempCouponCodeData, "offerIdForAndroid", row.offerId);
    }

    this.dialogStatus = "update";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private updateData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempCouponCodeData);
        const { data } = await adminApi.subscriptionUpdateCouponCodePost({
          couponCode: tempData.couponCode,
          productIdForAndroid: tempData.productIdForAndroid,
          basePlanIdForAndroid: tempData.basePlanIdForAndroid,
          offerIdForAndroid: tempData.offerIdForAndroid,
          productIdForIOS: tempData.productIdForIOS,
          offerIdForIOS: tempData.offerIdForIOS,
          limit: tempData.limit,
          expireDate: tempData.expireDate,
          rowKey: tempData.rowKey,
          partitionKey: tempData.partitionKey,
        });
        const index = this.list.findIndex(
          (v: any) =>
            v.partitionKey + v.rowKey ===
            data.data.partitionKey + data.data.rowKey
        );
        this.list.splice(index, 1, data.data);
        this.dialogFormVisible = false;
        this.$notify({
          title: "成功",
          message: "更新成功",
          type: "success",
          duration: 2000,
        });
      }
    });
  }
}

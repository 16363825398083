import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

/* Layout */
import Layout from "@/layout/index.vue";

/* Router modules */
import componentsRouter from "./modules/components";
import chartsRouter from "./modules/charts";
import tableRouter from "./modules/table";
import nestedRouter from "./modules/nested";

Vue.use(VueRouter);

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: "/redirect",
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ "@/views/redirect/index.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/email-login.vue"),
    meta: { hidden: true },
  },
  {
    path: "/email-login",
    name: 'email-login',
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/email-login.vue"),
    meta: { hidden: true },
  },
  {
    path: "/auth-redirect",
    component: () =>
      import(
        /* webpackChunkName: "auth-redirect" */ "@/views/login/auth-redirect.vue"
      ),
    meta: { hidden: true },
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/error-page/404.vue"),
    meta: { hidden: true },
  },
  {
    path: "/401",
    component: () =>
      import(/* webpackChunkName: "401" */ "@/views/error-page/401.vue"),
    meta: { hidden: true },
  },
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/dashboard/index.vue"
          ),
        name: "Dashboard",
        meta: {
          title: "dashboard",
          icon: "dashboard",
          affix: true,
        },
      },
    ],
  },
  // {
  //   path: '/documentation',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "documentation" */ '@/views/documentation/index.vue'),
  //       name: 'Documentation',
  //       meta: { title: 'documentation', icon: 'documentation', affix: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/guide',
  //   component: Layout,
  //   redirect: '/guide/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "guide" */ '@/views/guide/index.vue'),
  //       name: 'Guide',
  //       meta: {
  //         title: 'guide',
  //         icon: 'guide',
  //         noCache: true
  //       }
  //     }
  //   ]
  // },
  {
    path: "/profile",
    component: Layout,
    redirect: "/profile/index",
    meta: { hidden: true },
    children: [
      {
        path: "index",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/profile/index.vue"),
        name: "Profile",
        meta: {
          title: "profile",
          icon: "user",
          noCache: true,
        },
      },
    ],
  },
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes: RouteConfig[] = [
  {
    path: "/users",
    component: Layout,
    redirect: "/users/list",
    meta: {
      title: "users",
      icon: "user",
      // alwaysShow: true, // will always show the root menu
      roles: ["admin", "product-team"],
    },
    children: [
      {
        path: "list",
        component: () =>
          import(/* webpackChunkName: "zip" */ "@/views/users/index.vue"),
        name: "ListUser",
        meta: {
          title: "listUser",
          icon: "user",
        },
      },
      {
        path: "callEvents",
        component: () =>
          import(/* webpackChunkName: "zip" */ "@/views/callEvents/index.vue"),
        name: "callEvents",
        meta: {
          title: "callEvents",
          icon: "user",
        },
      },
      {
        path: "assistantConfig",
        component: () =>
          import(
            /* webpackChunkName: "zip" */ "@/views/assistantConfig/index.vue"
          ),
        name: "assistantConfig",
        meta: {
          title: "assistantConfig",
          icon: "user",
        },
      },
    ],
  },
  {
    path: "/mobile",
    component: Layout,
    redirect: "/mobile/localizations",
    meta: {
      title: "mobile",
      icon: "qq",
      roles: ["admin", "product-team"],
    },
    children: [
      {
        path: "localizations",
        component: () => import("@/views/applocalizations/index.vue"),
        name: "applocalizations",
        meta: { title: "applocalizations", icon: "size" },
      },
      {
        path: "otafiles",
        component: () => import("@/views/applocalizations/otafiles.vue"),
        name: "otafiles",
        meta: { title: "otafiles", icon: "size" },
      },
      {
        path: "localizations/detail",
        component: () => import("@/views/applocalizations/detail.vue"),
        name: "applocalizations_detail",
        meta: { hidden: true, title: "applocalizations_detail", icon: "size" },
      },
      {
        path: "coupons",
        component: () => import("@/views/appcoupons/index.vue"),
        name: "appcoupons",
        meta: { title: "appcoupons", icon: "shopping" },
      },
    ],
  },
  {
    path: "/mobility",
    component: Layout,
    redirect: "/mobility/addTrainerTrainee",
    meta: {
      title: "mobility",
      icon: "tree",
      roles: ["admin"], // you can set roles in root nav
      alwaysShow: false, // will always show the root menu
    },
    children: [
      {
        path: "addTrainerTrainee",
        component: () => import("@/views/mobility/addTrainerTrainee.vue"),
        name: "AddTrainerTrainee",
        meta: {
          title: "addTrainerTrainee",
          roles: ["admin"],
          icon: "education",
        },
      },
      // {
      //   path: 'listOrEditTrainerTrainee',
      //   component: () => import('@/views/mobility/listOrEditTrainerTrainee.vue'),
      //   name: 'ListOrEditTrainerTrainee',
      //   meta: {
      //     title: 'listOrEditTrainerTrainee',
      //     roles: ['admin']
      //   }
      // }
    ],
  },
  {
    path: "/packagemanagment",
    component: Layout,
    redirect: "/packagemanagment/list",
    meta: {
      title: "packageManagment",
      icon: "edit",
      roles: ["admin"], // you can set roles in root nav
      alwaysShow: false, // will always show the root menu
    },
    children: [
      {
        path: "list",
        component: () => import("@/views/packagemanagment/index.vue"),
        name: "PackageManagment",
        meta: {
          title: "packageManagment",
          roles: ["admin"],
          icon: "edit",
        },
      },
      // {
      //   path: 'listOrEditTrainerTrainee',
      //   component: () => import('@/views/mobility/listOrEditTrainerTrainee.vue'),
      //   name: 'ListOrEditTrainerTrainee',
      //   meta: {
      //     title: 'listOrEditTrainerTrainee',
      //     roles: ['admin']
      //   }
      // }
    ],
  },
  {
    path: "/sessions",
    component: Layout,
    redirect: "/sessions/list",
    meta: {
      title: "sessions",
      icon: "search",
      roles: ["admin"], // you can set roles in root nav
      alwaysShow: false, // will always show the root menu
    },
    children: [
      {
        path: "list",
        component: () => import("@/views/sessions/index.vue"),
        name: "sessions",
        meta: {
          title: "sessions",
          roles: ["admin"],
          icon: "search",
        },
      },
    ],
  },
  {
    path: "https://wewalk.io/",
    meta: {
      title: "wewalkPage",
      icon: "link",
    },
  },
  {
    path: "/danis",
    component: Layout,
    redirect: "/danis/config",
    name: "danis",
    meta: {
      title: "danis",
      icon: "danis",
      // alwaysShow: true, // will always show the root menu
      roles: ["admin", "product-team"],
    },
    children: [
      {
        path: "freeZone",
        component: () =>
          import(/* webpackChunkName: "zip" */ "@/views/freeZone/index.vue"),
        name: "freeZone",
        meta: {
          title: "freeZone",
          icon: "danis",
          roles: ["admin", "product-team"],
        },
      },
      {
        path: "company",
        component: () =>
          import(/* webpackChunkName: "zip" */ "@/views/company/index.vue"),
        name: "company",
        meta: {
          title: "company",
          icon: "danis",
          roles: ["admin", "product-team"],
        },
      },
      {
        path: "subject",
        component: () =>
          import(/* webpackChunkName: "zip" */ "@/views/subject/index.vue"),
        name: "subject",
        meta: {
          title: "subject",
          icon: "danis",
          roles: ["admin", "product-team"],
        },
      },
      {
        path: "brand",
        component: () =>
          import(/* webpackChunkName: "zip" */ "@/views/brand/index.vue"),
        name: "brand",
        meta: {
          title: "brand",
          icon: "danis",
          roles: ["admin", "product-team"],
        },
      },
      {
        path: "brand-subject",
        component: () =>
          import(
            /* webpackChunkName: "zip" */ "@/views/brandSubject/index.vue"
          ),
        name: "brandSubject",
        meta: {
          title: "brandSubject",
          icon: "danis",
          roles: ["admin", "product-team"],
        },
      },
            {
        path: "redirect-weassist",
        component: () =>
          import(
            /* webpackChunkName: "zip" */ "@/views/login/redirect-weassist.vue"
          ),
        name: "redirect-weassist",
        meta: {
          title: "redirect-weassist",
          icon: "danis",
          roles: ["admin", "product-team"],
        },
      },
    ],
  },
  // {
  //   path: '/permission',
  //   component: Layout,
  //   redirect: '/permission/directive',
  //   meta: {
  //     title: 'permission',
  //     icon: 'lock',
  //     roles: ['admin'], // you can set roles in root nav
  //     alwaysShow: true // will always show the root menu
  //   },
  //   children: [
  //     {
  //       path: 'page',
  //       component: () => import(/* webpackChunkName: "permission-page" */ '@/views/permission/page.vue'),
  //       name: 'PagePermission',
  //       meta: {
  //         title: 'pagePermission',
  //         roles: ['admin'] // or you can only set roles in sub nav
  //       }
  //     },
  //     {
  //       path: 'directive',
  //       component: () => import(/* webpackChunkName: "permission-directive" */ '@/views/permission/directive.vue'),
  //       name: 'DirectivePermission',
  //       meta: {
  //         title: 'directivePermission'
  //         // if do not set roles, means: this page does not require permission
  //       }
  //     },
  //     {
  //       path: 'role',
  //       component: () => import(/* webpackChunkName: "permission-role" */ '@/views/permission/role.vue'),
  //       name: 'RolePermission',
  //       meta: {
  //         title: 'rolePermission',
  //         roles: ['admin']
  //       }
  //     }
  //   ]
  // },
  {
    path: "/icon",
    component: Layout,
    meta: {
      roles: ["admin"],
    },
    children: [
      {
        path: "index",
        component: () =>
          import(/* webpackChunkName: "icons" */ "@/views/icons/index.vue"),
        name: "Icons",
        meta: {
          title: "icons",
          icon: "icon",
          noCache: true,
        },
      },
    ],
  },
  // /** when your routing map is too long, you can split it into small modules **/
  // componentsRouter,
  // chartsRouter,
  // nestedRouter,
  // tableRouter,
  // {
  //   path: '/example',
  //   component: Layout,
  //   redirect: '/example/list',
  //   meta: {
  //     title: 'example',
  //     icon: 'example',
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'create',
  //       component: () => import(/* webpackChunkName: "example-create" */ '@/views/example/create.vue'),
  //       name: 'CreateArticle',
  //       meta: {
  //         title: 'createArticle',
  //         icon: 'edit'
  //       }
  //     },
  //     {
  //       path: 'edit/:id(\\d+)',
  //       component: () => import(/* webpackChunkName: "example-edit" */ '@/views/example/edit.vue'),
  //       name: 'EditArticle',
  //       meta: {
  //         title: 'editArticle',
  //         noCache: true,
  //         activeMenu: '/example/list',
  //         hidden: true
  //       }
  //     },
  //     {
  //       path: 'list',
  //       component: () => import(/* webpackChunkName: "example-list" */ '@/views/example/list.vue'),
  //       name: 'ArticleList',
  //       meta: {
  //         title: 'articleList',
  //         icon: 'list'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/tab',
  //   component: Layout,
  //   meta: {
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "tab" */ '@/views/tab/index.vue'),
  //       name: 'Tab',
  //       meta: {
  //         title: 'tab',
  //         icon: 'tab'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/error',
  //   component: Layout,
  //   redirect: 'noredirect',
  //   meta: {
  //     title: 'errorPages',
  //     icon: '404',
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: '401',
  //       component: () => import(/* webpackChunkName: "error-page-401" */ '@/views/error-page/401.vue'),
  //       name: 'Page401',
  //       meta: {
  //         title: 'page401',
  //         noCache: true
  //       }
  //     },
  //     {
  //       path: '404',
  //       component: () => import(/* webpackChunkName: "error-page-404" */ '@/views/error-page/404.vue'),
  //       name: 'Page404',
  //       meta: {
  //         title: 'page404',
  //         noCache: true
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/error-log',
  //   component: Layout,
  //   redirect: 'noredirect',
  //   meta: {
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'log',
  //       component: () => import(/* webpackChunkName: "error-log" */ '@/views/error-log/index.vue'),
  //       name: 'ErrorLog',
  //       meta: {
  //         title: 'errorLog',
  //         icon: 'bug'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/excel',
  //   component: Layout,
  //   redirect: '/excel/export-excel',
  //   meta: {
  //     title: 'excel',
  //     icon: 'excel',
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'export-excel',
  //       component: () => import(/* webpackChunkName: "export-excel" */ '@/views/excel/export-excel.vue'),
  //       name: 'ExportExcel',
  //       meta: { title: 'exportExcel' }
  //     },
  //     {
  //       path: 'export-selected-excel',
  //       component: () => import(/* webpackChunkName: "select-excel" */ '@/views/excel/select-excel.vue'),
  //       name: 'SelectExcel',
  //       meta: { title: 'selectExcel' }
  //     },
  //     {
  //       path: 'export-merge-header',
  //       component: () => import(/* webpackChunkName: "merge-header" */ '@/views/excel/merge-header.vue'),
  //       name: 'MergeHeader',
  //       meta: { title: 'mergeHeader' }
  //     },
  //     {
  //       path: 'upload-excel',
  //       component: () => import(/* webpackChunkName: "upload-excel" */ '@/views/excel/upload-excel.vue'),
  //       name: 'UploadExcel',
  //       meta: { title: 'uploadExcel' }
  //     }
  //   ]
  // },
  // {
  //   path: '/zip',
  //   component: Layout,
  //   redirect: '/zip/download',
  //   meta: {
  //     title: 'zip',
  //     icon: 'zip',
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'download',
  //       component: () => import(/* webpackChunkName: "zip" */ '@/views/zip/index.vue'),
  //       name: 'ExportZip',
  //       meta: {
  //         title: 'exportZip',
  //         roles: ['admin']
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/pdf',
  //   component: Layout,
  //   redirect: '/pdf/index',
  //   meta: {
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "pdf" */ '@/views/pdf/index.vue'),
  //       name: 'PDF',
  //       meta: {
  //         title: 'pdf',
  //         icon: 'pdf',
  //         roles: ['admin']
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/pdf-download-example',
  //   component: () => import(/* webpackChunkName: "pdf-download-example" */ '@/views/pdf/download.vue'),
  //   meta: {
  //     hidden: true,
  //     roles: ['admin']
  //   }
  // },
  // {
  //   path: '/theme',
  //   component: Layout,
  //   redirect: 'noredirect',
  //   meta: {
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "theme" */ '@/views/theme/index.vue'),
  //       name: 'Theme',
  //       meta: {
  //         title: 'theme',
  //         icon: 'theme'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/clipboard',
  //   component: Layout,
  //   redirect: 'noredirect',
  //   meta: {
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "clipboard" */ '@/views/clipboard/index.vue'),
  //       name: 'Clipboard',
  //       meta: {
  //         title: 'clipboard',
  //         icon: 'clipboard'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/i18n',
  //   component: Layout,
  //   meta: {
  //     roles: ['admin']
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import(/* webpackChunkName: "i18n-demo" */ '@/views/i18n-demo/index.vue'),
  //       name: 'I18n',
  //       meta: {
  //         title: 'i18n',
  //         icon: 'international'
  //       }
  //     }
  //   ]
  // },
  {
    path: "*",
    redirect: "/404",
    meta: { hidden: true },
  },
];

const createRouter = () =>
  new VueRouter({
    // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;

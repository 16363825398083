var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [{ "is-active": _vm.isActive }], on: { click: _vm.toggleClick } },
    [
      _c("svg-icon", {
        attrs: { name: "hamburger", width: "20", height: "20" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AutocompletePredictionDetailResponse } from '../models';
import { AutocompletePredictionsResponse } from '../models';
import { ErrorModelResponse } from '../models';
import { GetAutocompletePredictionDetailRequest } from '../models';
import { GetAutocompletePredictionsRequest } from '../models';
import { GetRouteDetailRequest } from '../models';
import { GetRouteDetailResponse } from '../models';
import { GetRoutesPreviewRequest } from '../models';
import { GetRoutesPreviewResponse } from '../models';
import { GetRoutesRequest } from '../models';
import { GetWaitingLinesRequest } from '../models';
import { GetWaitingLinesResponse } from '../models';
import { RefreshRoutesResponse } from '../models';
/**
 * NavigationApi - axios parameter creator
 * @export
 */
export const NavigationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary New version of navigation, using Moovit API, shows the selected routes details
         * @param {GetRouteDetailRequest} body Request contains itineraryId obtained from getRoutesPreview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationGetRouteDetailPost: async (body: GetRouteDetailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling navigationGetRouteDetailPost.');
            }
            const localVarPath = `/navigation/getRouteDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets routes options, routes preview and steps based on latitude and longitude
         * @param {GetRoutesRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationGetRoutesPost: async (body: GetRoutesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling navigationGetRoutesPost.');
            }
            const localVarPath = `/navigation/getRoutes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary New version of navigation, using Moovit API, shows the route previews
         * @param {GetRoutesPreviewRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationGetRoutesPreviewPost: async (body: GetRoutesPreviewRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling navigationGetRoutesPreviewPost.');
            }
            const localVarPath = `/navigation/getRoutesPreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the lines passing by the stop that our user has arrived. This method works hand-to-hand with Google Maps and Azure Mobility Services. 
         * @param {GetWaitingLinesRequest} body Request contains coordinates for both departure and arrival with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationGetWaitingLinesPost: async (body: GetWaitingLinesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling navigationGetWaitingLinesPost.');
            }
            const localVarPath = `/navigation/getWaitingLines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets refreshed route for current location to next step's coordinates. Response is the same as getRoutes: options, routes preview and steps based on latitude and longitude
         * @param {GetAutocompletePredictionDetailRequest} body Request contains coordinates with latitude and longitude
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationPredictionsPlacePost: async (body: GetAutocompletePredictionDetailRequest, contentLanguage?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling navigationPredictionsPlacePost.');
            }
            const localVarPath = `/navigation/predictions/place`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (contentLanguage !== undefined && contentLanguage !== null) {
                localVarHeaderParameter['Content-Language'] = String(contentLanguage);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the autocomplete predictions from Google Maps Places SDK
         * @param {GetAutocompletePredictionsRequest} body Request contains query text and location of the user(lat,lng)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationPredictionsPost: async (body: GetAutocompletePredictionsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling navigationPredictionsPost.');
            }
            const localVarPath = `/navigation/predictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets refreshed route for current location to next step's coordinates. Response is the same as getRoutes: options, routes preview and steps based on latitude and longitude
         * @param {GetRoutesRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationRefreshRoutePost: async (body: GetRoutesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling navigationRefreshRoutePost.');
            }
            const localVarPath = `/navigation/refreshRoute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NavigationApi - functional programming interface
 * @export
 */
export const NavigationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary New version of navigation, using Moovit API, shows the selected routes details
         * @param {GetRouteDetailRequest} body Request contains itineraryId obtained from getRoutesPreview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navigationGetRouteDetailPost(body: GetRouteDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRouteDetailResponse>> {
            const localVarAxiosArgs = await NavigationApiAxiosParamCreator(configuration).navigationGetRouteDetailPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets routes options, routes preview and steps based on latitude and longitude
         * @param {GetRoutesRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navigationGetRoutesPost(body: GetRoutesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoutesPreviewResponse>> {
            const localVarAxiosArgs = await NavigationApiAxiosParamCreator(configuration).navigationGetRoutesPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary New version of navigation, using Moovit API, shows the route previews
         * @param {GetRoutesPreviewRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navigationGetRoutesPreviewPost(body: GetRoutesPreviewRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRoutesPreviewResponse>> {
            const localVarAxiosArgs = await NavigationApiAxiosParamCreator(configuration).navigationGetRoutesPreviewPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the lines passing by the stop that our user has arrived. This method works hand-to-hand with Google Maps and Azure Mobility Services. 
         * @param {GetWaitingLinesRequest} body Request contains coordinates for both departure and arrival with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navigationGetWaitingLinesPost(body: GetWaitingLinesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWaitingLinesResponse>> {
            const localVarAxiosArgs = await NavigationApiAxiosParamCreator(configuration).navigationGetWaitingLinesPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets refreshed route for current location to next step's coordinates. Response is the same as getRoutes: options, routes preview and steps based on latitude and longitude
         * @param {GetAutocompletePredictionDetailRequest} body Request contains coordinates with latitude and longitude
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navigationPredictionsPlacePost(body: GetAutocompletePredictionDetailRequest, contentLanguage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompletePredictionDetailResponse>> {
            const localVarAxiosArgs = await NavigationApiAxiosParamCreator(configuration).navigationPredictionsPlacePost(body, contentLanguage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the autocomplete predictions from Google Maps Places SDK
         * @param {GetAutocompletePredictionsRequest} body Request contains query text and location of the user(lat,lng)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navigationPredictionsPost(body: GetAutocompletePredictionsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutocompletePredictionsResponse>> {
            const localVarAxiosArgs = await NavigationApiAxiosParamCreator(configuration).navigationPredictionsPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets refreshed route for current location to next step's coordinates. Response is the same as getRoutes: options, routes preview and steps based on latitude and longitude
         * @param {GetRoutesRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navigationRefreshRoutePost(body: GetRoutesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshRoutesResponse>> {
            const localVarAxiosArgs = await NavigationApiAxiosParamCreator(configuration).navigationRefreshRoutePost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NavigationApi - factory interface
 * @export
 */
export const NavigationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary New version of navigation, using Moovit API, shows the selected routes details
         * @param {GetRouteDetailRequest} body Request contains itineraryId obtained from getRoutesPreview
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationGetRouteDetailPost(body: GetRouteDetailRequest, options?: any): AxiosPromise<GetRouteDetailResponse> {
            return NavigationApiFp(configuration).navigationGetRouteDetailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets routes options, routes preview and steps based on latitude and longitude
         * @param {GetRoutesRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationGetRoutesPost(body: GetRoutesRequest, options?: any): AxiosPromise<GetRoutesPreviewResponse> {
            return NavigationApiFp(configuration).navigationGetRoutesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary New version of navigation, using Moovit API, shows the route previews
         * @param {GetRoutesPreviewRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationGetRoutesPreviewPost(body: GetRoutesPreviewRequest, options?: any): AxiosPromise<GetRoutesPreviewResponse> {
            return NavigationApiFp(configuration).navigationGetRoutesPreviewPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the lines passing by the stop that our user has arrived. This method works hand-to-hand with Google Maps and Azure Mobility Services. 
         * @param {GetWaitingLinesRequest} body Request contains coordinates for both departure and arrival with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationGetWaitingLinesPost(body: GetWaitingLinesRequest, options?: any): AxiosPromise<GetWaitingLinesResponse> {
            return NavigationApiFp(configuration).navigationGetWaitingLinesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets refreshed route for current location to next step's coordinates. Response is the same as getRoutes: options, routes preview and steps based on latitude and longitude
         * @param {GetAutocompletePredictionDetailRequest} body Request contains coordinates with latitude and longitude
         * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationPredictionsPlacePost(body: GetAutocompletePredictionDetailRequest, contentLanguage?: string, options?: any): AxiosPromise<AutocompletePredictionDetailResponse> {
            return NavigationApiFp(configuration).navigationPredictionsPlacePost(body, contentLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the autocomplete predictions from Google Maps Places SDK
         * @param {GetAutocompletePredictionsRequest} body Request contains query text and location of the user(lat,lng)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationPredictionsPost(body: GetAutocompletePredictionsRequest, options?: any): AxiosPromise<AutocompletePredictionsResponse> {
            return NavigationApiFp(configuration).navigationPredictionsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets refreshed route for current location to next step's coordinates. Response is the same as getRoutes: options, routes preview and steps based on latitude and longitude
         * @param {GetRoutesRequest} body Request contains coordinates with latitude and longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationRefreshRoutePost(body: GetRoutesRequest, options?: any): AxiosPromise<RefreshRoutesResponse> {
            return NavigationApiFp(configuration).navigationRefreshRoutePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NavigationApi - object-oriented interface
 * @export
 * @class NavigationApi
 * @extends {BaseAPI}
 */
export class NavigationApi extends BaseAPI {
    /**
     * 
     * @summary New version of navigation, using Moovit API, shows the selected routes details
     * @param {GetRouteDetailRequest} body Request contains itineraryId obtained from getRoutesPreview
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public navigationGetRouteDetailPost(body: GetRouteDetailRequest, options?: any) {
        return NavigationApiFp(this.configuration).navigationGetRouteDetailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets routes options, routes preview and steps based on latitude and longitude
     * @param {GetRoutesRequest} body Request contains coordinates with latitude and longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public navigationGetRoutesPost(body: GetRoutesRequest, options?: any) {
        return NavigationApiFp(this.configuration).navigationGetRoutesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary New version of navigation, using Moovit API, shows the route previews
     * @param {GetRoutesPreviewRequest} body Request contains coordinates with latitude and longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public navigationGetRoutesPreviewPost(body: GetRoutesPreviewRequest, options?: any) {
        return NavigationApiFp(this.configuration).navigationGetRoutesPreviewPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the lines passing by the stop that our user has arrived. This method works hand-to-hand with Google Maps and Azure Mobility Services. 
     * @param {GetWaitingLinesRequest} body Request contains coordinates for both departure and arrival with latitude and longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public navigationGetWaitingLinesPost(body: GetWaitingLinesRequest, options?: any) {
        return NavigationApiFp(this.configuration).navigationGetWaitingLinesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets refreshed route for current location to next step's coordinates. Response is the same as getRoutes: options, routes preview and steps based on latitude and longitude
     * @param {GetAutocompletePredictionDetailRequest} body Request contains coordinates with latitude and longitude
     * @param {string} [contentLanguage] Content language, like &#x27;tr&#x27;, &#x27;en&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public navigationPredictionsPlacePost(body: GetAutocompletePredictionDetailRequest, contentLanguage?: string, options?: any) {
        return NavigationApiFp(this.configuration).navigationPredictionsPlacePost(body, contentLanguage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the autocomplete predictions from Google Maps Places SDK
     * @param {GetAutocompletePredictionsRequest} body Request contains query text and location of the user(lat,lng)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public navigationPredictionsPost(body: GetAutocompletePredictionsRequest, options?: any) {
        return NavigationApiFp(this.configuration).navigationPredictionsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets refreshed route for current location to next step's coordinates. Response is the same as getRoutes: options, routes preview and steps based on latitude and longitude
     * @param {GetRoutesRequest} body Request contains coordinates with latitude and longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationApi
     */
    public navigationRefreshRoutePost(body: GetRoutesRequest, options?: any) {
        return NavigationApiFp(this.configuration).navigationRefreshRoutePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * WeWalk API V2.0
 * Backend service for WeWALK Mobile Applications, written in **TypeScript** and runs on **NodeJS**. Using **MongoDB** for database, **mongoose** for ORM, **Redis Cache** for caching auth tokens. In cloud, runs in Azure App Services (wewalk-backend-v2). For cloud database, it uses MongoDB inside Azure Cosmos DB. For passwordless authentication, Firebase Authentication is used, other than that, users can use email-password sign-in as well. Transportation services are fed by [Azure Maps Mobility Services](https://docs.microsoft.com/en-us/rest/api/maps/mobility) [RAW JSON](/api/v2/documentation) - [Swagger Editor](https://editor.swagger.io) <br><br> Production Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI'/> <br><br>Test Service Health: <img src='https://dev.azure.com/wewalksmartcane/wewalk-api-v2/_apis/build/status/wewalk-api-v2-CI-staging'/>
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export * from "./api";
export * from "./configuration";


var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: { placeholder: _vm.placeholder, error: _vm.inputError },
        on: { input: _vm.validateInput },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _c("div", { staticClass: "error-message" }, [
        _vm._v(_vm._s(_vm.inputError)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { "show-timeout": 100, trigger: "click" } },
    [
      _c("el-button", { attrs: { plain: "" } }, [
        _vm._v(
          " " +
            _vm._s(
              !_vm.disableComment ? "Comment: opened" : "Comment: closed"
            ) +
            " "
        ),
        _c("i", { staticClass: "el-icon-caret-bottom el-icon--right" }),
      ]),
      _c(
        "el-dropdown-menu",
        {
          staticClass: "no-padding",
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _c(
            "el-dropdown-item",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { padding: "10px" },
                  model: {
                    value: _vm.disableComment,
                    callback: function ($$v) {
                      _vm.disableComment = $$v
                    },
                    expression: "disableComment",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v(" Close comment "),
                  ]),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v(" Open comment "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }